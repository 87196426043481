import { Container } from '@mui/material';
import React, { Fragment, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer'
import { Toaster } from '../components/UI/Toaster';
function Layout({ children }) {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <Fragment>
      <Container>
        <Toaster />
        <Header />
        {children}

      </Container>
      <Footer />

    </Fragment>
  );
}

export default Layout;