import { Box, Button, Dialog, DialogContent, Slide, TextField, Typography } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'

import AvTimerOutlinedIcon from '@mui/icons-material/AvTimerOutlined';
import Images from '../../assets/Images/Images';
import { useForm } from 'react-hook-form';
import { Service } from '../../config/service';
import useAuth from './../../hooks/useAuth';
import CloseIcon from '@mui/icons-material/Close';
import { ErrorToaster, SuccessToaster } from '../UI/Toaster';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Otp = ({ openDialog, onClose, data, startTimer }) => {

    const { register, handleSubmit, reset } = useForm();
    const { signIn } = useAuth();

    // *For Resend OTP
    const [resendOtpTimer, setResendOtpTimer] = useState(true);
    const [mins, setMins] = useState(0);

    const [sec, setSec] = useState(0);


    const resend = async () => {
        let obj = {
            phoneno: data.phoneno
        }
        try {
            await Service.resendOTP(obj)
            setMins(0)
            setSec(0)
            setResendOtpTimer(true)
            otpTimer()
            SuccessToaster("Otp has been sent on your phone number")
        } catch (error) {
            ErrorToaster(error)
        }
    }

    const submitForm = async (otpValue) => {
        try {
            let otp = otpValue.value1 + otpValue.value2 + otpValue.value3 + otpValue.value4
            let merged = { ...data, ...{ otp_code: otp } };

            const { message, Data } = await Service.verifySignup(merged)
            reset()
            onClose()
            SuccessToaster(message)
            signIn(Data.name, Data.tokens, Data.image)
        } catch (error) {
            ErrorToaster(error)
        }
    }

    // *For Otp Timer
    const otpTimer = () => {
        try {

            const duration = 60 * 1
            var timer = duration, minutes, seconds;
            var timeInterval = setInterval(function () {
                minutes = parseInt(timer / 60, 10);
                seconds = parseInt(timer % 60, 10);

                minutes = minutes < 10 ? "0" + minutes : minutes;
                seconds = seconds < 10 ? "0" + seconds : seconds;
                setMins(minutes)
                setSec(seconds)
                if (--timer < 0) {
                    timer = duration;
                    setResendOtpTimer(false)
                    clearInterval(timeInterval)
                }
            }, 1000);
        } catch (error) {
            console.log('file: Otp.js => line 107 => otpTimer => error', error)
        }
    }

    useEffect(() => {
        if (startTimer) {
            otpTimer()
        }
    }, [startTimer]);

    return (
        <Fragment>
            <Dialog
                open={openDialog}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
                PaperProps={{
                    style: {
                        backgroundColor: "#F9F9F9",
                        boxShadow: "0px 1px 5px #00000012",
                        borderRadius: '14px',
                        width: '380px'
                    },
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 1, mx: '17px' }}>
                    <CloseIcon onClick={onClose} sx={{ color: '#898B8F', fontSize: '28px', cursor: 'pointer' }} />
                </Box>
                <Box sx={{ display: 'block', mx: 'auto', mt: 2 }}>
                    <img src={Images.pic1} style={{ width: "80%" }} ></img>
                </Box>
                <DialogContent >
                    <Typography sx={{ fontSize: '15px', color: '#484848', p: 1, textAlign: 'center' }}>
                        <strong>Verify Otp</strong>
                    </Typography>
                    <Box id="alert-dialog-slide-description">
                        <Box sx={{ '& > :not(style)': { m: 1 } }}>
                            <form onSubmit={handleSubmit(submitForm)} >
                                <Box style={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                                    <TextField sx={{ mx: 1, width: '15%' }} size="small" inputProps={{ maxLength: 1 }}
                                        {...register("value1", {
                                            required: "required",
                                            minLength: {
                                                value: 1,
                                                message: "Please enter the code"
                                            }
                                        })} />
                                    <TextField sx={{ mx: 1, width: '15%' }} size="small" inputProps={{ maxLength: 1 }}
                                        {...register("value2", {
                                            required: "required",
                                            minLength: {
                                                value: 1,
                                                message: "Please enter the code"
                                            }
                                        })} />
                                    <TextField sx={{ mx: 1, width: '15%' }} size="small" inputProps={{ maxLength: 1 }}
                                        {...register("value3", {
                                            required: "required",
                                            minLength: {
                                                value: 1,
                                                message: "Please enter the code"
                                            }
                                        })} />
                                    <TextField sx={{ mx: 1, width: '15%' }} size="small" inputProps={{ maxLength: 1 }}
                                        {...register("value4", {
                                            required: "required",
                                            minLength: {
                                                value: 1,
                                                message: "Please enter the code"
                                            }
                                        })} />
                                </Box>
                                <Button type="submit" variant="contained" sx={{ bgcolor: '#3B5998', px: 3, py: 1, my: 2, borderRadius: 2, width: '100%' }} >Verify</Button>

                                {!resendOtpTimer ?
                                    <Button sx={{ px: 3, py: 1, borderRadius: 2, width: '100%', }}
                                        onClick={resend} >Resend</Button>
                                    :
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <AvTimerOutlinedIcon />
                                        <Typography sx={{ textAlign: 'center', m: 1 }}>Resend Otp In {mins}:{sec}</Typography>
                                    </Box>
                                }

                            </form>

                        </Box>
                    </Box>

                </DialogContent>

            </Dialog>

        </Fragment>
    )

}

export default Otp
