import { Box, Button, CardMedia, Container, Divider, Grid, Typography, MenuItem, FormControl, Card, CardContent, CardActions, Autocomplete, TextField, IconButton, Toolbar, List, ListSubheader, ListItem, ListItemText, TableHead, Table, TableRow, TableCell, TableContainer, Paper, } from '@mui/material';
import Images from '../../assets/Images/Images';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Layout from '../../layout/Layout';

import SimpleCard from '../../components/Card/SImpleCard';
import Categories from '../../components/Categories/Categories';
import { Link, useNavigate } from 'react-router-dom';
import { Service } from './../../config/service';
import React, { useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import { LoadingButton } from '@mui/lab';
import Slide from '@mui/material/Slide';
import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import MoreIcon from '@mui/icons-material/MoreVert';
import Fab from '@mui/material/Fab';
import { Scrollbar } from 'swiper';

function Home() {

	const { user } = useAuth();
	const navigate = useNavigate();
	const token = localStorage.getItem('token') || ''

	const [search, setSearch] = useState('');
	const [popularCategories, setPopularCategories] = useState([]);
	const [recentAds, setRecentAds] = useState([]);

	const [autoCompleteDropDown, setAutoCompleteDropDown] = useState([]);
	const [totalAds, setTotalAds] = useState(0);
	const [pageNumber, setPageNumber] = useState(1);

	const [loading, setLoading] = useState(false);

	async function fetchCategoryData() {
		const { Data } = await Service.getCategory()
		setPopularCategories(Data)
	}

	const getRecentAds = async (p) => {
		setLoading(true)
		try {
			const page = p !== undefined ? p + pageNumber : pageNumber
			setPageNumber(page)
			const { Data, total_ads_count } = await Service.recentAds(page, token)
			setRecentAds([...recentAds, ...Data])
			setTotalAds(total_ads_count)
		} catch (error) {
			console.log("🚀 ~ file: Home.js ~ line 51 ~ getRecentAds ~ error", error)

		} finally {
			setLoading(false)
		}
	}

	const getAutoCompleteDropDown = async () => {
		try {
			const search = ''
			const data = await Service.autoCompleteDropDown(search)
			setAutoCompleteDropDown(data)
		} catch (error) {
			console.log('file: Home.js => line 53 => getAutoCompleteDropDown => error', error)
		}
	}

	const handleChange = (event, value) => {
		try {
			let searchValue
			if (value) {
				searchValue = value
			} else {
				searchValue = event.target.value
			}
			setSearch(searchValue)
		}
		catch (error) {
			console.log("🚀 ~ file: Home.js ~ line 77 ~ handleChange ~ error", error)

		}
	}

	const handleClick = async () => {
		navigate(`/item?search=${search}`)
	}

	useEffect(() => {
		fetchCategoryData()
		getAutoCompleteDropDown()
	}, [])

	useEffect(() => {
		getRecentAds()
	}, [user])

	return (
		<Layout>

			<Divider />
			<Box p={2} sx={{ display: { md: "flex", xs: "none" }, justifyContent: "space-between" }}>
				<Typography sx={{ color: "#484848", fontSize: { xl: 20, md: 17, xs: 12, }, px: 1 }} >
					Mobile
				</Typography>
				<Typography sx={{ color: "#484848", fontSize: { xl: 20, md: 17, xs: 12 }, px: 1 }} >
					Vehicles
				</Typography>
				<Typography sx={{ color: "#484848", fontSize: { xl: 20, md: 17, xs: 12 }, px: 1 }} >
					Property
				</Typography>
				<Typography sx={{ color: "#484848", fontSize: { xl: 20, md: 17, xs: 12 }, px: 1 }} >
					Electronics
				</Typography>
				<Typography sx={{ color: "#484848", fontSize: { xl: 20, md: 17, xs: 12 }, px: 1 }} >
					Services
				</Typography>
				<Typography sx={{ color: "#484848", fontSize: { xl: 20, md: 17, xs: 12 }, px: 1 }} >
					Jobs
				</Typography>
				<Typography sx={{ color: "#484848", fontSize: { xl: 20, md: 17, xs: 12 }, px: 1 }} >
					Household
				</Typography>
			</Box>



			<TableContainer component={Paper} sx={{ display: { md: "none", sm: "flex" }, my: 2, }}>
				<Table sx={{ minWidth: 650 }} >
					<TableHead>
						<TableRow>
							<TableCell sx={{ color: "#484848", fontSize: { xl: 20, md: 17, xs: 14, }, px: 1 }} >
								Mobile
							</TableCell>
							<TableCell sx={{ color: "#484848", fontSize: { xl: 20, md: 17, xs: 14 }, px: 1 }} >
								Vehicles
							</TableCell>
							<TableCell sx={{ color: "#484848", fontSize: { xl: 20, md: 17, xs: 14 }, px: 1 }} >
								Property For Rent
							</TableCell>
							<TableCell sx={{ color: "#484848", fontSize: { xl: 20, md: 17, xs: 14 }, px: 1 }} >
								Property For Sale
							</TableCell>
							<TableCell sx={{ color: "#484848", fontSize: { xl: 20, md: 17, xs: 14 }, px: 1 }} >
								Services
							</TableCell>
							<TableCell sx={{ color: "#484848", fontSize: { xl: 20, md: 17, xs: 14 }, px: 1 }} >
								Jobs
							</TableCell>
							<TableCell sx={{ color: "#484848", fontSize: { xl: 20, md: 17, xs: 14 }, px: 1 }} >
								Community
							</TableCell>
						</TableRow>
					</TableHead>
				</Table>
			</TableContainer>


			<Divider />

			<Box sx={{ p: { md: 2, xs: 0.5 } }} width="100%" >
				<CardMedia
					height="100%"
					sx={{ paddingY: 2, width: "100%" }}
					image={Images.pic2}
				>
					<Box sx={{ width: { md: "80%", xs: "85%" }, backgroundColor: "rgba(0, 0, 0, 0.5)", marginX: { md: 10, xs: 1.5 }, marginY: 10, borderRadius: "2%", px: { md: 2, xs: 2 }, py: { md: 2, xs: 1 } }}>

						<Box sx={{ display: "flex", mb: 2 }}>
							<Grid container spacing={0.1}>
								<Grid item md={1.6} xs={4}>
									<MenuItem >
										<Typography sx={{
											fontSize: { xl: 20, md: 17, xs: 12 },
											color: "#FFFFFF",

										}} >
											Searching in
										</Typography>
									</MenuItem>
								</Grid>
								<Grid item md={1} xs={4}>
									<MenuItem >
										<Button sx={{
											bgcolor: "#2c457a",
											borderRadius: 5,
											color: "#FFFFFF",
											fontSize: { xl: 15, md: 12, xs: 12 },
										}} >
											All
										</Button>
									</MenuItem >
								</Grid>
								<Grid item md={4} xs={2} sx={{ display: "flex" }}>

									{popularCategories.slice(0, 5).map((item, i) => (
										<MenuItem >
											<Typography sx={{ display: { md: "flex", xs: "none" }, color: "#FFFFFF", fontSize: { xl: 20, md: 17, xs: 11 }, }} >
												{item.name}
											</Typography>
										</MenuItem >
									))}
								</Grid>

							</Grid>

						</Box>

						<Box >
							<Grid container spacing={0.5}  >
								<Grid item md={10} xs={12} sm={12} sx={{

								}}>
									<FormControl sx={{ width: '100%', backgroundColor: "#FFFFFF", borderRadius: "2%" }}>
										{/* <Autocomplete
											options={autoCompleteDropDown}
											getOptionLabel={(option) => option.title}
											sx={{ width: "100%" }}
											onChange={handleChange}
											renderInput={(params) => <TextField {...params} onChange={handleChange} placeholder="What are you looking for?" sx={{ color: "#BCBCBC" }} />} /> */}
										<Autocomplete
											freeSolo
											disableClearable
											options={autoCompleteDropDown.map((option) => option.title)}
											onChange={handleChange}
											renderInput={(params) => (
												<TextField

													{...params}
													onChange={handleChange}
													placeholder="What are you looking for?"
													sx={{ color: "#BCBCBC", }}
													InputProps={{
														...params.InputProps,
														type: 'search',
													}}
												/>
											)}
										/>
									</FormControl>

								</Grid>
								<Grid item xs={12} md={2} sx={{
									// width: 500,
									// maxWidth: '100%',
								}}>
									<Button onClick={() => handleClick()} variant="contained" startIcon={<SearchOutlinedIcon />} sx={{
										backgroundColor: "#3B5998",
										width: { md: "100%", xs: "100%" },
										height: 55,
										fontSize: { xl: 20, md: 15 },
									}}>
										Search
									</Button>
								</Grid>
							</Grid>
						</Box>

					</Box>
				</CardMedia>

			</Box>

			<Box>
				<Typography variant='h5' px={1} sx={{ color: "#484848", fontSize: { xl: 20, md: 25, xs: 23 }, letterSpacing: "0.57px", paddingY: 4 }}>
					<strong>Popular Categories</strong>
				</Typography>
				<Categories data={popularCategories} />
			</Box>


			<Box p={1}>
				<Typography variant='h5' p={0.5} sx={{ color: "#484848", letterSpacing: "0.57px", fontWeight: "90%", fontSize: "23px", fontFamily: "sans-serif", paddingY: 4 }}>
					<strong>Latest Ads</strong>
				</Typography>
				<SimpleCard data={recentAds} />
				{totalAds !== recentAds.length &&
					<Box sx={{ textAlign: "center", my: 2 }} >
						<LoadingButton sx={{ mt: 4, mb: -1, bgcolor: "#2c457a", borderRadius: 2, fontWeight: 600 }} loading={loading} variant='contained' onClick={() => getRecentAds(1)}>
							Load More
						</LoadingButton>
					</Box>
				}
			</Box>





		</Layout >


	)
}
export default Home
