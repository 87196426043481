import React, { Fragment, useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import HttpsIcon from '@mui/icons-material/Https';
import PhoneIcon from '@mui/icons-material/Phone';
import CloseIcon from '@mui/icons-material/Close';
import { FormControl, IconButton, Input, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material';
import { useForm } from "react-hook-form";
import { Service } from '../../config/service';
import Images from '../../assets/Images/Images';
import { MailOutline, VisibilityOff } from '@mui/icons-material';
import Forget from '../Forget/Forget';
import useAuth from './../../hooks/useAuth';
import { LoadingButton } from '@mui/lab';
import Visibility from '@mui/icons-material/Visibility';
import { ErrorToaster, SuccessToaster } from '../UI/Toaster';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SignIn = ({ openDialog, onClose }) => {

  const { register, handleSubmit, watch, setError, formState: { errors }, reset } = useForm({});
  const password = useRef({});
  password.current = watch("password", "");

  const [openForgetDialog, setOpenForgetDialog] = useState(false);
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const submitForm = async (data) => {
    setLoading(true)
    try {
      let phone = /^((\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$/
      let email = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
      let obj = {
        password: data.password,
        email: '',
        phoneno: ''
      }
      if (data.emailorphone) {
        if (email.test(data.emailorphone)) {
          obj.email = data.emailorphone
        }
        else if (phone.test(data.emailorphone)) {
          obj.phoneno = data.emailorphone
        }
        else {
          setError("emailorphone", {
            type: 'custom',
            message: 'Entered value does not match email or phone no format',
          });
        }
      }

      const { response, status, message, Data } = await Service.signIn(obj)

      if (status === true && response === 200) {
        onClose()
        reset()
        signIn(Data.name, Data.tokens, Data.image);
        SuccessToaster(message)

      }
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  };

  return (
    <Fragment>
      <Forget openDialog={openForgetDialog} onClose={() => setOpenForgetDialog(false)} />

      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"

        PaperProps={{
          style: {
            backgroundColor: "#F9F9F9",
            boxShadow: "0px 1px 5px #00000012",
            borderRadius: '14px',
            width: '380px'
          },
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 1, mx: '17px' }}>
          <CloseIcon onClick={onClose} sx={{ color: '#898B8F', fontSize: '28px', cursor: 'pointer' }} />
        </Box>
        <Box sx={{ display: 'block', mx: 'auto', my: '-18px' }}>
          <img src={Images.pic1}  ></img>
        </Box>
        <DialogContent >
          <Typography sx={{ fontSize: '15px', color: '#484848', p: 1 }}>Sign to your Account</Typography>
          <Box id="alert-dialog-slide-description">
            <Box sx={{ '& > :not(style)': { m: 1 } }}>
              <form onSubmit={handleSubmit(submitForm)}>

                <Box sx={{ my: 2, py: 1, display: 'flex', alignItems: 'flex-end', bgcolor: 'white', borderRadius: '12px' }}>
                  <MailOutline sx={{ color: 'action.active', m: 1, my: 0.5 }} />
                  <TextField size="medium" fullWidth name="emailorphone" placeholder="Email or Phone" variant="standard"
                    {...register("emailorphone", {
                      required: "required",

                    })}
                    InputProps={{ disableUnderline: true }}
                    required />
                </Box>
                {errors.emailorphone && <span role="alert" style={{ color: 'red' }}>{errors.emailorphone.message}</span>}

                <Box sx={{ my: 2, display: 'flex', alignItems: 'flex-end', bgcolor: 'white', borderRadius: '12px' }}>
                  <HttpsIcon sx={{ color: 'action.active', mb: 1.5 }} />
                  <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                    {/* <InputLabel>Password</InputLabel> */}
                    <Input
                      placeholder='password'
                      disableUnderline
                      id="outlined-adornment-password"
                      type={values.showPassword ? 'text' : 'password'}

                      {...register("password", {
                        required: "required",
                        minLength: {
                          value: 6,
                          message: "min length is 6"
                        }
                      })}

                      sx={{ width: "100%" }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }

                    />
                  </FormControl>
                </Box>

                {/* <FormControl fullWidth>
                    <TextField fullWidth name="password" placeholder="Password" variant="standard" InputProps={{ disableUnderline: true }}

                      type={values.showPassword ? 'text' : 'password'}
                      value={values.password}
                      onChange={handleChange('password')}
                      {...register("password", {
                        required: "required",
                        minLength: {
                          value: 6,
                          message: "min length is 6"
                        }
                      })}

                    >
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    </TextField>

                  </FormControl> */}


                {errors.password && <span style={{ color: 'red' }} role="alert">{errors.password.message}</span>}

                <LoadingButton loading={loading} type="submit" variant="contained" sx={{ bgcolor: '#3B5998', px: 3, py: 1, borderRadius: 2, width: '95%' }} >SIGN IN</LoadingButton>
                <Button sx={{ mt: 2, px: 3, py: 1, borderRadius: 2, width: '95%' }} onClick={() => { setOpenForgetDialog(true); onClose(true) }}  >Forget Password</Button>
              </form>

            </Box>
          </Box>
          {/* <Typography sx={{ fontSize: '14px', textAlign: 'center', mt: 2 }}>Sign In With</Typography> */}

        </DialogContent>
        {/* <Box sx={{ display: 'flex', justifyContent: 'space-around', mb: 3, p: 1 }}>
          <Button sx={{ px: 5, py: 2, bgcolor: 'white', borderRadius: "12px" }} ><img src={Images.google}></img></Button>
          <Button sx={{ px: 5, py: 2, bgcolor: 'white', borderRadius: "12px" }}><img src={Images.facebook}></img></Button>
          <Button sx={{ px: 5, py: 2, bgcolor: 'white', borderRadius: "12px", boxShadow: '0px 2px 6px #3B599826' }}><img src={Images.email}></img></Button>
        </Box> */}
      </Dialog>

    </Fragment>
  )
}


export default SignIn