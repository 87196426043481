
import { LoadingButton } from '@mui/lab';
import { Box, Button, CardMedia, Container, Divider, FormControl, Grid, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import Images from '../../assets/Images/Images'
import { Service } from '../../config/service';
import Layout from '../../layout/Layout'
import { SuccessToaster } from '../../components/UI/Toaster';
function Contactus() {

  const { register, handleSubmit, formState: { errors }, reset } = useForm({});
  const [loading, setLoading] = useState(false);
  const commonStyles = {

    m: 3,
    border: 3,
    width: '30rem',
    height: '23rem',
  };

  const sendForm = async (formData) => {

    setLoading(true)
    try {
      let obj = {
        firstName: formData.name,
        email: formData.email,
        subject: formData.subject,
        body: formData.message
      }

      const { status, response, message } = await Service.contactForm(obj)
      if (status === true && response === 200) {
        reset()
        SuccessToaster(message)
      }

    } catch (error) {
      console.log("🚀 ~ file: Contactus.js ~ line 25 ~ sendForm ~ error", error)

    } finally {
      setLoading(false)
    }

  }


  return (

    <Layout>

      <Box sx={{ height: "100%" }}>
        <CardMedia
          sx={{
            width: "100%",
            borderRadius: 7,
            height: "100%",
            overflow: "hidden",
            my: { md: 4, xs: 2 }

          }}
          image={Images.contactus}
          alt="green iguana"
        >
          <Box sx={{ height: "100%", width: "100%", textAlign: "center" }}>
            <Box sx={{
              textAlign: "center",
              p: { md: 4, xs: "none" }

            }}>
              <Typography variant='h3' sx={{
                fontSize: { xl: 20, md: 50, xs: 30 },
                p: { md: 4, xs: 2 },
                fontFamily: "barlow",
                fontWeight: 700,
                color: "#F3F8FE",
                mx: { md: 6, xs: "none" }
              }}>
                We’d <span style={{ color: "#3B5998" }} >Love</span> to Hear From You
              </Typography>
              <Typography sx={{
                fontSize: { xl: 20, md: 20, xs: 14 },
                p: { md: 4, xs: 2 },
                fontFamily: "poppins",

                fontWeight: 500,
                color: "#C5C6C7",
                mx: { md: 6, xs: "none" }
              }}>
                Whether you’re  curious about features, a free trial, or even press—we’re
                ready to answer any and all questions.
              </Typography>
            </Box>

          </Box>

        </CardMedia>

      </Box>
      <Container>
        <Grid container spacing={1}
          sx={{
            display: "flex",
            justifyContent: "left",
            flexWrap: { xs: "wrap-reverse" },
            ml: 5
          }}>
          <Grid item xs={8} md={3} sx={{ my: 1 }} >
            <CardMedia
              component="img"
              width="100%"
              image={Images.chatwithus}
              alt="green iguana"
            />
          </Grid>
          <Grid item xs={10} md={4} >
            <Typography variant='h5' fontWeight="bold"
              sx={{
                fontSize: { xl: 20, md: 30, xs: 38 },
                py: 2,
                textAlign: 'left',
                mt: 4,
                fontFamily: 'Barlow',
                fontWeight: 600,
                color: "#303030"
              }}>
              Contact a specialist
              <Divider fontWeight='bold' sx={{
                background: "black",

              }} />
            </Typography>
            <Typography
              component="div" fontWeight="bold"
              sx={{
                fontSize: { xl: 20, md: 14, xs: 15 },
                fontFamily: "poppins",
                // fontSize: "14px",
                fontWeight: 400,
                color: "#303030"



              }} >
              When it comes to joining an e-Commerce business,
              timing.
              When it comes to joining an e-Commerce business,
              timing When it comes to joining an e-Commerce business,
              timing
            </Typography>
            <Button variant="contained" size="large" sx={{
              fontSize: '16px',
              fontWeight: "bold",
              mt: 2,
              bgcolor: " #3B5998"
            }}>
              Let's Chat
            </Button>

          </Grid>
        </Grid>
        <Grid container spacing={1}
          sx={{
            display: "flex",
            justifyContent: "right",
            flexWrap: { xs: "wrap-reverse" },
            mr: 10
          }}>
          <Grid item xs={8} md={3} sx={{ my: 1 }}>
            <CardMedia
              component="img"
              width="50%"
              image={Images.getsupport}
              alt="green iguana"
            />
          </Grid>
          <Grid item xs={10} md={4} >
            <Typography variant='h5' fontWeight="bold"
              sx={{
                fontSize: { xl: 20, md: 30, xs: 38 },
                py: 2,
                textAlign: 'left',
                mt: 4,
                fontFamily: 'Barlow',
                fontWeight: 600,
                color: "#303030"
              }}>
              Get support
              <Divider fontWeight='bold' sx={{
                background: "black",

              }} />
            </Typography>
            <Typography
              component="div" fontWeight="bold"
              sx={{
                fontSize: { xl: 20, md: 14, xs: 15 },
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 400,
                color: "#303030"


              }} >
              Looking for help in the builder or have questions about your
              existing account When it comes to joining an e-Commerce business,
              timing When it comes to joining an e-Commerce business,
              timing
            </Typography>
            <Button variant="contained" size="large" sx={{


              fontSize: '16px',
              fontWeight: "bold",
              mt: 3,
              bgcolor: " #3B5998"
            }}>
              Get Support
            </Button>

          </Grid>
        </Grid>
        <Grid container spacing={1}
          sx={{
            display: "flex",
            justifyContent: "left",
            flexWrap: { xs: "wrap-reverse" },
            ml: 5
          }}>
          <Grid item xs={8} md={3} sx={{ my: 1 }}>
            <CardMedia
              component="img"
              width="50%"
              image={Images.mediainquiry}
              alt="green iguana"
            />
          </Grid>
          <Grid item xs={10} md={4} >
            <Typography variant='h5' fontWeight="bold"
              sx={{
                fontSize: { xl: 20, md: 30, xs: 38 },
                py: 2,
                textAlign: 'left',
                mt: 4,
                fontFamily: 'Barlow',
                fontWeight: 600,
                color: "#303030"
              }}>
              Media inquiries
              <Divider fontWeight='bold' sx={{
                background: "black",

              }} />
            </Typography>
            <Typography
              component="div" fontWeight="bold"
              sx={{
                fontSize: { xl: 20, md: 14, xs: 15 },
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 400,
                color: "#303030"


              }} >
              When it comes to joining an e-Commerce business,
              timing When it comes to joining an e-Commerce business,
              timing When it comes to joining an e-Commerce business,
              timing
            </Typography>
            <Button variant="contained" size="large" sx={{
              fontSize: '16px',
              fontWeight: "bold",
              mt: 2,
              bgcolor: " #3B5998"
            }}>
              Get In Touch
            </Button>

          </Grid>
        </Grid>
      </Container>



      <Box sx={{
        height: "100%", width: "100%", display: 'flex',
      }}>
        <Grid container spacing={4} py={5} sx={{ flexWrap: { xs: "wrap-reverse" } }}>
          <Grid item xs={12} md={6}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12172.241433155496!2d67.07322205545489!3d24.903812817929495!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc8d3cbe5a24547d4!2sMangoTech%20Solutions!5e0!3m2!1sen!2s!4v1653456937800!5m2!1sen!2s"
              width="100%" height="530"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade" />

          </Grid>

          <Grid item md={6}>
            <Box
              component="form"
              onSubmit={handleSubmit(sendForm)}
            >
              <FormControl
                sx={{
                  width: '100%', py: 2
                }}>
                <TextField
                  error={errors?.name?.message && (true)}
                  placeholder="Please enter name"
                  {...register('name', {
                    required: "Please Enter name",

                  })}
                />
                {errors.name && <span role="alert" style={{ color: 'red' }}>{errors.name.message}</span>}
              </FormControl>
              <FormControl sx={{ width: '100%', py: 2 }}>
                <TextField type="email"
                  error={errors?.email?.message && (true)}
                  placeholder="Please enter email"
                  {...register('email', {
                    required: "Please Enter email",

                  })}
                />
                {errors.email && <span role="alert" style={{ color: 'red' }}>{errors.email.message}</span>}
              </FormControl>
              <FormControl sx={{ width: '100%', py: 2 }}>
                <TextField
                  error={errors?.subject?.message && (true)}
                  placeholder="Please enter subject"
                  {...register('subject', {
                    required: "Please Enter subject",

                  })}
                />
                {errors.subject && <span role="alert" style={{ color: 'red' }}>{errors.subject.message}</span>}
              </FormControl>
              <FormControl sx={{ width: '100%', py: 2 }}>
                <TextField
                  error={errors?.message?.message && (true)}
                  multiline rows={5} placeholder="Please enter message"
                  {...register('message', {
                    required: "Please Enter message",

                  })}
                />
                {errors?.message?.message && <span role="alert" style={{ color: 'red' }}>{errors.message.message}</span>}
              </FormControl>
              <LoadingButton loading={loading} type="submit" variant="contained" fullWidth sx={{ borderRadius: 2, p: 2, bgcolor: "#3B5998", }}>Send</LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Box>





    </Layout>

  )
}
export default Contactus