import { FavoriteBorderOutlined, FavoriteIcon } from '@mui/icons-material'
import { Box, Card, CardContent, CardMedia, CircularProgress, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Service } from '../../config/service'
import Layout from '../../layout/Layout'
import moment from 'moment'
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
const Favourite = () => {
    const token = localStorage.getItem('token') || ''
    const [userAds, setUserAds] = useState();
    const addFav = async (id) => {

        try {
            let obj = {
                ad_Id: id
            }
            const { response } = await Service.addFav(obj, token)

            if (response === 200) {
                fetchData()
            }
        } catch (error) {
            console.log(error)
        }
    }
    const fetchData = async () => {
        try {
            const { Data } = await Service.user_fav_Ads(token)
            setUserAds(Data.fave_advertise)
        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        fetchData()
    }, [])

    return (
        <Layout>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} sx={{ py: 3 }} >
                    <Typography sx={{ fontSize: "25px", fontWeight: 700, mb: 2 }}>Favourite </Typography>
                    <Grid container spacing={2}>
                        {userAds ?
                            userAds.map((ad, i) => {
                                return (
                                    <Grid key={i} item lg={3} md={3} xs={6} sx={{ mb: 2 }} >
                                        <Card sx={{ maxWidth: 235 }}>
                                            <Link to={`/ads/${ad._id}`}>
                                                <CardMedia
                                                    component="img"
                                                    height="210px"
                                                    sx={{ width: "235px !important" }}
                                                    alt="load"
                                                    src={ad.images[0]}
                                                />
                                            </Link>
                                            <CardContent>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Typography noWrap variant="body2" component="div" sx={{ color: "#484848", fontSize: "14px" }}>
                                                        {ad.title}
                                                    </Typography>

                                                    <FavoriteOutlinedIcon
                                                        sx={{ color: '#3B5998', cursor: "pointer" }}
                                                        onClick={() => addFav(ad._id)} />
                                                </Box>
                                                <Typography variant="body2"
                                                    sx={{ color: "#484848", fontWeight: 700, fontSize: "16px" }}>
                                                    Rs. {ad.price}
                                                </Typography>
                                                <Box
                                                    sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Typography variant="body2" sx={{ color: "#898B8F", fontWeight: 700, fontSize: "8px", paddingTop: 2 }}>
                                                        {ad.location}
                                                    </Typography>
                                                    <Typography variant="body2"
                                                        sx={{ color: "#898B8F", fontWeight: 700, fontSize: "8px", paddingTop: 2, textAlign: "right" }}>
                                                        {moment(ad.posted_On).format('DD MMM YYYY')}
                                                    </Typography>
                                                </Box>
                                            </CardContent>

                                        </Card>
                                    </Grid>
                                )
                            }) :
                            <Box
                                sx={{ margin: 'auto' }}>
                                <CircularProgress />
                            </Box>}


                    </Grid>
                </Grid>

            </Grid>



        </Layout>
    )
}

export default Favourite