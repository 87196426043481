import React, { useEffect, useState } from 'react'
import Layout from '../../layout/Layout'
import { Avatar, Box, Card, CardContent, CardMedia, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import { EventNoteOutlined, FavoriteBorderOutlined, FavoriteOutlinedIcon, LocationOnOutlined } from '@mui/icons-material';
import { Service } from '../../config/service';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SignIn from '../../components/SignIn/SignIn';

const Profile = () => {
    const token = localStorage.getItem('token') || ''
    let { id } = useParams()
    const [user, setUser] = useState({});
    const [userAds, setUserAds] = useState([]);
    const [favAdd, setFavAdd] = useState([]);
    const [openSignInDialog, setOpenSignInDialog] = useState(false);

    const addFav = async (id) => {
        try {
            if (!token) {
                setOpenSignInDialog(true);
                return
            }
            let obj = {
                ad_Id: id
            }
            const { response } = await Service.addFav(obj, token)
            if (response === 200) {
                let array = [...favAdd]
                const index = array.findIndex(element => element === id)
                if (index === -1) {
                    array.push(id)
                } else {
                    array.splice(index, 1)
                }
                setFavAdd(array)
            }
        } catch (error) {
        }
    }
    const fetchData = async () => {
        const { Data } = await Service.userAds(id, token)
        setUser(Data.user)
        setUserAds(Data.ads)
        // fetchUser(id)
        defaultFav(Data.ads)
    }
    // const fetchUser = async (id) => {
    //     try {

    //         const { Data } = await Service.user(id)
    //         setUser(Data)
    //     }
    //     catch (error) {
    //         console.log(error)
    //     }
    // }

    const defaultFav = (data) => {
        try {
            let array = []
            data.forEach(element => {
                if (element.favourite === true) {
                    array.push(element._id)
                }
            });
            setFavAdd(array)
        } catch (error) {

        }
    }

    useEffect(() => {
        fetchData()

    }, [])

    return (
        <Layout>
            <SignIn openDialog={openSignInDialog} onClose={() => setOpenSignInDialog(false)} />
            {userAds.length > 0 ?
                <Box sx={{ p: 3, width: 1 }} >
                    <Grid container spacing={6} >
                        <Grid item lg={3} xs={12} sm={12} sx={{}}>
                            <Avatar
                                alt={user.name}
                                src={user.image}
                                sx={{ m: 'auto', width: '9.4rem', height: '9.4rem' }}
                            />
                            <Typography sx={{ textAlign: 'center', my: 1, fontSize: { md: "18px", xs: "15px" } }}>Joined:{moment(user.createdAt).format('DD MMM YYYY')}</Typography>
                        </Grid>
                        <Grid item lg={8} xs={12}>
                            <Typography sx={{ fontSize: { md: '2.9rem', xs: "2.5rem" } }}>{user.name}</Typography>
                            <Divider />
                            <Typography sx={{ fontWeight: 700, py: 2 }}>Published ads </Typography>
                            <Grid container spacing={2} >
                                {userAds.map((ad, i) => {
                                    return (

                                        <Grid key={i} item lg={4} xs={6} sx={{ my: 1, }}>

                                            <Card sx={{ maxWidth: "100%" }}>
                                                <Link to={`/ads/${ad._id}`}>
                                                    <CardMedia
                                                        component="img"
                                                        height="220px"
                                                        sx={{ width: "235px !important", }}
                                                        alt="..."
                                                        src={ad.images[0]}
                                                    />
                                                </Link>
                                                <CardContent>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>

                                                        <Typography noWrap variant="body2" component="div" sx={{ color: "#484848", fontSize: "14px" }}>
                                                            {ad.title}
                                                        </Typography>


                                                        {favAdd.findIndex(e => e === ad._id) !== -1 ?
                                                            <FavoriteIcon sx={{ color: '#2c457a', cursor: 'pointer' }} onClick={() => addFav(ad._id)} />
                                                            :
                                                            <FavoriteBorderOutlined sx={{ cursor: 'pointer', ':hover': { color: '#2c457a' } }} onClick={() => addFav(ad._id)} />
                                                        }
                                                    </Box>
                                                    <Typography variant="body2" sx={{ color: "#484848", fontWeight: 700, fontSize: "16px" }}>
                                                        Rs. {ad.price}
                                                    </Typography>
                                                    <Box sx={{ pt: { md: 1, xs: 1 } }}>
                                                        <Box>
                                                            <LocationOnOutlined sx={{ fontSize: { md: "15px", xs: "18px" }, verticalAlign: 'bottom' }} />
                                                            <Typography component={"span"} variant="body2" sx={{ color: "#898B8F", ml: 0.5, fontWeight: 700, fontSize: { md: "9px", xs: "11px" }, }}>
                                                                {ad.subdivision_name}, {ad.city_name}
                                                            </Typography>
                                                        </Box>
                                                        <Box>
                                                            <EventNoteOutlined sx={{ fontSize: { md: "15px", xs: "18px" }, verticalAlign: 'bottom' }} />
                                                            <Typography component={"span"} variant="body2" sx={{ color: "#898B8F", ml: 0.5, fontWeight: 700, fontSize: { md: "9px", xs: "11px" }, textAlign: "right", }}>
                                                                {moment(ad.posted_On).format('DD MMM YYYY')}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    {/* <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Typography variant="body2" sx={{ color: "#898B8F", fontWeight: 700, fontSize: "8px", paddingTop: 2 }}>
                                                            {ad.location}
                                                        </Typography>
                                                        <Typography variant="body2" sx={{ color: "#898B8F", fontWeight: 700, fontSize: "8px", paddingTop: 2, textAlign: "right" }}>
                                                            {moment(ad.posted_On).format('DD MMM YYYY')}
                                                        </Typography>
                                                    </Box> */}
                                                </CardContent>

                                            </Card>

                                        </Grid>

                                    )
                                })}

                            </Grid>


                        </Grid>

                    </Grid>
                </Box>
                :
                <Box sx={{ width: "100%", textAlign: "center" }}>
                    <CircularProgress />
                </Box>

            }
        </Layout>
    )
}

export default Profile