import { Autocomplete, Box, Breadcrumbs, Button, Divider, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import Layout from '../../layout/Layout';
import { set, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Service } from './../../config/service';
import useAuth from '../../hooks/useAuth';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import { LoadingButton } from '@mui/lab';
import AdPost from './Adpost';
import { ErrorToaster } from '../../components/UI/Toaster';

const Bedrooms = [
  {
    value: 'a',
    label: 'a',
  },
  {
    value: 'b',
    label: 'b',
  },
  {
    value: 'c',
    label: 'c',
  },
  {
    value: 'd',
    label: 'd',
  },
];

function Propertyform() {
  const { register, handleSubmit, formState: { errors }, setValue, clearErrors } = useForm();
  const { state } = useLocation()

  const [post, setPost] = useState(false)
  const [cities, setCities] = useState([]);
  const [division, setDivision] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedValue, setSelectedValue] = React.useState('a');
  const [areaEnable, setAreaEnable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [cityId, setCityId] = useState();
  const [subDivsionid, setsubDivsionid] = useState();
  const [adPostDialogBox, setAdPostDialogBox] = useState(false);

  const navigate = useNavigate();

  const [currency, setCurrency] = React.useState('EUR');

  const handleChange2 = (event) => {
    setCurrency(event.target.value);
  };


  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  }

  const fetchCities = async () => {
    const { Data } = await Service.allCities()
    setCities(Data)
  }
  const fetchDivision = async (id) => {
    const { Data } = await Service.getSub_Division(id)
    setDivision(Data)
  }
  useEffect(() => {
    fetchCities()
  }, [])
  const handleLocation = (value) => {

    setValue("location", value._id)
    setCityId(value._id)
    fetchDivision(value._id);
    clearErrors('location');
    setAreaEnable(false)

  }

  const token = localStorage.getItem('token') || ''
  const hiddenFileInput = useRef(null);
  const [images, setImages] = useState([]);
  const [renderImages, setRenderImages] = useState([]);
  const [titleLength, setTitleLength] = useState(0);


  const { signOut } = useAuth()

  const handleClick = event => {
    hiddenFileInput.current.click();

  };
  const handleLength = (event) => {
    setTitleLength(event.target.value.length)
    clearErrors('title')
  }
  const handleImage = async (e) => {

    let files = e.target.files;
    for (var i = 0; i < files.length; i++) {
      let file = files[i];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        let fileInfo = {
          image: reader.result,
        };
        const { Data } = await Service.insertImage(fileInfo, token)
        setImages(images => [...images, Data]);
      }
    }
  }

  const removeImage = (i) => {
    const filteredArray = renderImages.filter((image, index) => index !== i);
    setRenderImages(filteredArray)
    const filteredUrlArray = images.filter((image, index) => index !== i);
    setImages(filteredUrlArray)
  }

  const submitForm = async (adsdata) => {

    setLoading(true)
    try {
      let obj = {
        title: adsdata.title,
        price: adsdata.price,
        description: adsdata.description,
        category_Id: state._id,
        images: images,
        city_id: cityId,
        subdivision_id: subDivsionid,
      }
      const { response, status, message } = await Service.insertAds(obj, token)
      if (status === true && response === 200) {
        setAdPostDialogBox(true)
      }
    } catch (error) {
      ErrorToaster(error)
      if ("Invalid Token" === error) {
        setTimeout(() => {
          signOut()
        }, "3000")
      }

    } finally {
      setLoading(false)
    }

  }
  return (
    <Layout>
      <Box sx={{ bgcolor: "#F7F8F8", p: { md: 4, xs: "none" } }}>
        <AdPost openDialog={adPostDialogBox} onClose={() => setAdPostDialogBox(false)} />
        <Box role="presentation" sx={{ px: { md: 7, xs: 1 } }}>
          <Breadcrumbs aria-label="breadcrumb" sx={{ fontSize: { md: "25px", xs: "20px" }, my: 2 }} >
            <Typography component={Link} to="/" sx={{ color: "#2c457a", fontSize: { md: "18px", xs: "15px" } }}> Home</Typography>
            <Typography component={Link} to="/sell" sx={{ color: "#2c457a", fontSize: { md: "18px", xs: "15px" } }}> Sell</Typography>

            <Typography color="#2c457a" sx={{ fontSize: { md: "18px", xs: "15px" }, mt: 0.3 }} >Test</Typography>
          </Breadcrumbs>
        </Box>
        <Box sx={{ px: { md: 4, xs: "none" }, py: { md: 4, xs: 2 } }}>
          <Typography sx={{ color: "#484848", fontWeight: "80%", fontSize: { md: "25px", xs: "20px" }, px: { md: 3, xs: 1 } }}>
            <strong>POST YOUR AD</strong>
          </Typography>
        </Box>

        <Box component="form" sx={{ px: { md: 7, xs: "none" } }} onSubmit={handleSubmit(submitForm)}>
          <Button onClick={handleClick} sx={{ bgcolor: "#3B5998", height: "40%", width: "100%", display: "flex", justifyContent: "center", mb: 4 }}>
            <Typography sx={{ py: 6, color: "#FFFFFF" }}>
              <PhotoSizeSelectActualIcon sx={{ fontSize: { md: "57px", xs: "35px" }, mr: { md: 2, xs: 1 } }} />
              <AddCircleIcon sx={{ fontSize: { md: "37px", xs: "27px" }, mr: { md: 4, xs: 2 }, mb: { md: 1, xs: 0.5 } }} />
            </Typography>
            <Typography sx={{ py: 6, color: "#FFFFFF", mt: { md: 1.5, xs: "none" }, fontWeight: 300 }}>Add Images</Typography>
          </Button>
          <input type="file" multiple
            accept="image/*"
            ref={hiddenFileInput}
            onChange={handleImage}
            style={{ display: 'none' }}


          />
          {errors.image && <span role="alert" style={{ color: 'red' }}>{errors.image.message}</span>}
          <Box sx={{ display: 'flex' }}>

            {images.map((url, i) => (
              <Box key={i}>
                <img src={url} alt="..." width='100px' height='100px' style={{ padding: 7 }} />

                <IconButton component="span"
                  disableRipple
                  sx={{
                    position: 'relative',
                    left: '-19%',
                    bottom: "65%",
                    bgcolor: '#F2951F', padding: "0px"
                  }} onClick={() => removeImage(i)}>

                  <CloseIcon sx={{ color: 'white', fontSize: '15px' }} />
                </IconButton>
                {i === 0 &&
                  <Typography sx={{ bgcolor: '#3B5998', color: "white", width: '100px', textAlign: 'center', position: 'relative', bottom: '22%', marginLeft: '6px', opacity: '0.9' }}>Cover</Typography>
                }

              </Box>
            ))}
          </Box>

          <Typography sx={{ color: "#484848", fontWeight: 600, fontSize: "20px", mb: 2 }}>
            Ad Title
          </Typography>
          <FormControl fullWidth>
            <TextField
              error={errors?.title?.message && (true)}
              variant="outlined" sx={{ width: '100%', mb: '6px' }}
              inputProps={{ maxLength: 70 }}
              {...register('title', {
                required: "Please Enter Ad Title",
              })}
              onChange={handleLength}
            />
          </FormControl>

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ fontSize: "13px", color: '#707070', paddingBottom: 4 }}>
              Mention the key features of your item (e.g. brand, model, age, type)
            </Typography>
            {errors.title && <span role="alert" style={{ color: 'red' }}>{errors.title.message}</span>}
            <Typography sx={{ fontSize: '12px' }}>
              {titleLength} /70
            </Typography>
          </Box>
          <Typography sx={{ color: "#484848", fontWeight: 600, fontSize: "20px", mb: 2 }}>
            Description
          </Typography>
          <TextField
            error={errors?.description?.message && (true)}
            variant="outlined" multiline rows={5}
            {...register('description', {
              required: "Please Enter description",

            })} fullWidth sx={{ width: '100%', borderRadius: 4, mb: '6px' }} />

          <Typography sx={{ fontSize: "13px", color: '#707070', paddingBottom: 4 }}>
            Include condition, features and reason for selling
          </Typography>
          {errors?.description?.message && <span role="alert" style={{ color: 'red' }}>{errors.description.message}</span>}
          <Typography sx={{ paddingY: 2, fontWeight: 600, color: "#002F34" }}>
            Furnished
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Radio
              checked={selectedValue === 'a'}
              onChange={handleChange}
              value="a"
              name="radio-buttons"
              inputProps={{ 'aria-label': 'A' }}
              sx={{ mt: -1 }}
            />
            <Typography>Furnished</Typography>
            <Radio
              checked={selectedValue === 'b'}
              onChange={handleChange}
              value="b"
              name="radio-buttons"
              inputProps={{ 'aria-label': 'B' }}
              sx={{ mt: -1 }}
            />
            <Typography>UnFurnished</Typography>
          </Box>
          <Divider />
          <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
            <TextField
              sx={{ width: "49%", paddingRight: -1 }}
              id="filled-select-bedrooms"
              select
              label="Bedrooms"
              value={Bedrooms}
              onChange={handleChange2}

              variant="filled"
            >
              {Bedrooms.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              sx={{ width: "50%", paddingleft: -1 }}
              id="filled-select-bedrooms"
              select
              label="Bathrooms"
              value={Bedrooms}
              onChange={handleChange2}

              variant="filled"
            >
              {Bedrooms.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Divider />
          <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
            <TextField
              sx={{ width: "49%", paddingRight: -1 }}
              id="select-bedrooms"
              select
              label="Area"
              value={Bedrooms}
              onChange={handleChange2}

              variant="filled"
            >
              {Bedrooms.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              sx={{ width: "50%", paddingleft: -1 }}
              id="select-bedrooms"
              select
              label="Area Units"
              value={Bedrooms}
              onChange={handleChange2}

              variant="filled"
            >
              {Bedrooms.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <Box sx={{ display: "flex", mt: 2 }}>
          </Box>
          <Divider />

          <Typography sx={{ color: "#484848", fontWeight: 600, fontSize: "20px", paddingY: 2 }}>
            Set A Price
          </Typography>

          <TextField
            type='number' label="Your Price Here" variant="outlined"
            error={errors?.price?.message && (true)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <IconButton>
                    <LocalOfferOutlinedIcon sx={{ fontSize: '27px', color: "#3B5998" }} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            {...register('price', {
              required: "Please Enter price",
            })}
            sx={{ width: '100%', mb: '6px' }} />
          {errors?.price?.message && <span role="alert" style={{ color: 'red' }}>{errors.price.message}</span>}

          <Typography sx={{ color: "#484848", fontWeight: 600, fontSize: "20px", paddingY: 2 }}>

            YOUR AD'S LOCATION

          </Typography>
          <Box sx={{ display: "flex", justifyContent: 'space-between' }}>
            <Box sx={{ width: '100%' }}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={cities}
                  getOptionLabel={(option) => option.name}
                  sx={{ mr: 1 }}
                  {...register('location', {
                    required: "Please select location"
                  })}
                  onChange={(event, value) => { handleLocation(value) }}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}
                    >
                      {option.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <>
                      <TextField
                        error={errors?.subject?.message && (true)}
                        {...params}
                        placeholder='Location'
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <AddLocationAltOutlinedIcon />
                            </InputAdornment>
                          )

                        }}
                      />
                    </>
                  )}
                />
                {errors?.location?.message && <span role="alert" style={{ color: 'red' }}>{errors.location.message}</span>}
              </FormControl>
            </Box>
            {/* <FormControl fullWidth >
              <InputLabel id="location-label">Location</InputLabel>
              <Select
                labelId="location-label"
                // defaultValue=''
                label="location"
                sx={{ mr: 3 }}
                {...register('location', {
                  required: "Please select location"
                })}
              >
                {cities.map((city, i) => {
                  return (<MenuItem key={i} sx={{ height: '40px' }} value={city.name}
                    onClick={() => fetchDivision(city._id)}
                  >{city.name}</MenuItem>)
                })}
              </Select>
            </FormControl> */}

            <Box sx={{ width: '100%' }}>
              <FormControl fullWidth>
                <Autocomplete
                  disabled={areaEnable}
                  disablePortal
                  id="combo-box-demo"
                  options={division}
                  getOptionLabel={(option) => option.Subdivison_name}
                  error={errors?.local_area?.message && (true)}
                  {...register('local_area', {
                    required: "Please select local area"
                  })}
                  onChange={(event, value) => { setValue("local_area", value._id); setsubDivsionid(value._id); clearErrors('local_area') }}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}
                    >
                      {option.Subdivison_name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        placeholder='Area'
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <AddLocationAltOutlinedIcon />
                            </InputAdornment>
                          )
                        }}
                      />
                    </>
                  )}
                />
                {errors?.local_area?.message && <span role="alert" style={{ color: 'red' }}>{errors?.local_area?.message}</span>}
              </FormControl>
            </Box>
            {/* <FormControl fullWidth>
              <InputLabel id="area-label">
                <Box sx={{ display: 'flex' }}>
                  <LocalOfferOutlinedIcon sx={{ fontSize: '27px', color: "#3B5998" }} />
                  <Typography sx={{ ml: 1 }}>Area</Typography>
                </Box>
              </InputLabel>
              <Select
                labelId="area-label"
                defaultValue=''
                label="Area"
                {...register('local_area', {
                  required: "Please select local area"
                })}
              >
                {division.map((division, i) => {
                  return (<MenuItem key={i} sx={{ height: '40px' }}
                    value={division.Subdivison_name}
                  >{division.Subdivison_name}</MenuItem>)
                })}
              </Select>
            </FormControl> */}
          </Box>
          <Box sx={{ textAlign: "right" }}>
            <LoadingButton loading={loading} type='submit' variant="contained" sx={{ width: { md: "50%", xs: "100%" }, p: 1.5, backgroundColor: "#3B5998", marginY: 4, borderRadius: 2.4, }}>Post Now</LoadingButton>
          </Box>
        </Box>
      </Box >



    </Layout >
  )
}

export default Propertyform