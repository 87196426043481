import React, { Fragment, useEffect, useState } from 'react'
import { Button, Typography, TextField, FormControl, IconButton, InputAdornment, CircularProgress, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EditIcon from '@mui/icons-material/Edit';
import Layout from '../../layout/Layout';
import { Service } from './../../config/service';
import moment from 'moment'
import { Link, useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { SuccessToaster } from '../../components/UI/Toaster';

let timer = null;

const MyAds = () => {

    const token = localStorage.getItem('token') || ''
    const [myAds, setMyAds] = useState();
    const { id } = useParams()
    const [active, setActive] = useState('');
    const [search, setSearch] = useState('');

    // *For Total Ads
    const [totalAds, setTotalAds] = useState(0);

    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);

    const fetchData = async (st, val) => {
        setLoading(true)
        try {
            const status = st === null ? active : st
            const searchKey = val === null ? search : val
            const page = 1
            const { count, data } = await Service.myAds(status, searchKey, page, token)
            setMyAds(data)
            setTotalAds(count)
        }
        catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }


    const changePage = async (p) => {
        setLoading(true)
        try {
            const page = p + 1
            const { count, data } = await Service.myAds(active, search, page, token)
            setTotalAds(count)
            setMyAds([...myAds, ...data])
        } catch (error) {
            console.log('file: MyAds.js => line 51 => changePage => error', error)
        } finally {
            setLoading(false)
        }
    }


    const changeAdStatus = async (id) => {
        setLoading2(true)
        try {
            const data = ''
            const { response, message } = await Service.changeAdStatus(id, data, token)
            if (response === 200) {
                fetchData(null, null)
                SuccessToaster(message)
            }
        } catch (error) {
            console.log('file: MyAds.js => line 51 => changePage => error', error)
        } finally {
            setLoading2(false)
        }
    }

    useEffect(() => {
        fetchData(null, null)
    }, [])

    const handleChange = (event) => {
        const value = event.target.value;
        setSearch(value)
        clearTimeout(timer);
        timer = setTimeout(() => {
            fetchData(null, value)
        }, 1000);
    }

    return (
        <Layout>
            <Box sx={{ bgcolor: 'white', p: 3 }}>
                <Typography sx={{ fontWeight: 'bold', pl: 2 }}>MY ADS</Typography>
                <Box sx={{ display: { md: "flex", xs: "block" }, justifyContent: 'space-between', p: 2, alignItems: 'center' }}>
                    <FormControl sx={{ width: { md: "30%", xs: "100%" } }}>
                        <TextField
                            placeholder="Search By Ad Title"
                            onChange={handleChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" >
                                        <IconButton >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </FormControl>
                    <Box>
                        <Button variant='outlined'
                            sx={{ mx: { md: 2, xs: 0.5 }, my: { xs: 1 }, color: '#898B8F', borderColor: "#898B8F", fontWeight: 600, px: { md: 1 }, py: { xs: 1 }, width: { md: 126, xs: "30%" } }}
                            onClick={() => { setActive(''); fetchData('', null) }}>
                            View All
                        </Button>
                        <Button variant='outlined'
                            sx={{ mx: { md: 2, xs: 0.5 }, my: { xs: 1 }, color: '#898B8F', borderColor: "#898B8F", fontWeight: 600, px: { md: 1 }, py: { xs: 1 }, width: { md: 126, xs: "30%" } }}
                            onClick={() => { setActive(true); fetchData(true, null) }}>
                            Active
                        </Button>
                        <Button variant='outlined'
                            sx={{ mx: { md: 2, xs: 0.5 }, my: { xs: 1 }, color: '#898B8F', borderColor: "#898B8F", fontWeight: 600, px: { md: 1 }, py: { xs: 1 }, width: { md: 126, xs: "30%" } }}
                            onClick={() => { setActive(false); fetchData(false, null) }}>
                            InActive
                        </Button>
                    </Box>
                </Box>
                {myAds ?
                    myAds.length > 0 ?
                        <Fragment>
                            {myAds.map((item, i) => {
                                return (
                                    <Box key={i} sx={{ width: "100%" }}>
                                        <Box sx={{ my: 2, width: "100%", bgcolor: '#EBEBEB', display: 'flex', justifyContent: 'space-between', p: { md: 1, xs: 1 }, my: { md: 2, xs: "none" } }}>
                                            <Box sx={{ display: 'flex', }}>
                                                <CalendarMonthOutlinedIcon sx={{ color: '#484848' }} />
                                                <Typography sx={{ color: '#484848', pl: 1, fontWeight: 600, fontSize: '18px' }}>
                                                    {moment(item.posted_On).format('DD MMM YYYY')}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Link to={`/edit-ads/${item._id}`} >
                                                    <EditIcon sx={{ mr: 2, color: 'black' }} />
                                                </Link>
                                                {/* <FormatListBulletedOutlinedIcon /> */}
                                            </Box>
                                        </Box>
                                        <Grid container >


                                            <Grid item md={4} xs={12}>
                                                <Box sx={{ mt: 1, mb: -2 }}>
                                                    <img style={{ borderRadius: '5%', width: { xs: "auto" }, objectFit: "contain" }}
                                                        src={item.images[0]}
                                                        alt='...'
                                                        width='235px'
                                                        height="250" />
                                                </Box>
                                            </Grid>
                                            <Grid item md={6} xs={12} sx={{}}>
                                                <Box sx={{ width: '100%', mt: 5 }}>
                                                    <Typography sx={{ fontSize: { md: "26px", xs: "22px" }, fontWeight: { xs: 600 } }}>{item.title}</Typography>
                                                    <Typography sx={{ fontSize: { md: "28px", xs: "22px" }, fontWeight: 600 }}>RS: {item.price}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item md={4} xs={12}>
                                            </Grid>
                                            <Grid item md={8} xs={12} sx={{ mt: { md: -7, xs: "none" }, ml: { md: -3, xs: "none" }, display: "flex", justifyContent: "space-between" }} >

                                                <Box sx={{ display: 'flex', }}>
                                                    <VisibilityOutlinedIcon sx={{ color: '#898B8F', fontSize: { md: "18px", xs: "15px" }, mt: 1.8, mx: 0.5 }} />
                                                    <Typography sx={{ color: '#898B8F', fontSize: { md: "18px", xs: "15px" }, mt: 1.3, mr: 2 }}>Views : {item.views}</Typography>
                                                    <FavoriteBorderOutlinedIcon sx={{ color: '#898B8F', fontSize: { md: "18px", xs: "15px" }, mt: 1.8, mx: 0.5 }} />
                                                    <Typography sx={{ color: '#898B8F', fontSize: { md: "18px", xs: "15px" }, mt: 1.3, mr: 2 }}>Likes : 0</Typography>
                                                </Box>


                                                <Box sx={{ display: { md: "flex", xs: "flex" }, }}>
                                                    <LoadingButton loading={loading2} variant="contained" onClick={() => changeAdStatus(item._id)} sx={{ color: 'white', bgcolor: '#3B5998', py: 0.3, borderRadius: 2, my: { md: 1, xs: 1 }, mx: { md: 1, xs: 0.2 }, width: { xs: "100%", md: "auto" } }}>{item.is_Active ? "UnPublish" : "Publish"} </LoadingButton>
                                                    <Link to={`/ads/${item._id}`}>
                                                        <Button variant="contained" sx={{ color: 'white', bgcolor: '#3B5998', px: 4, py: 1.3, borderRadius: 2, my: { md: 1, xs: 1 }, mx: { md: 1, xs: 0.2 }, width: { xs: "100%", md: "auto" } }} >View</Button>
                                                    </Link>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>



                                )
                            })}
                            {myAds.length !== totalAds &&
                                <Box sx={{ textAlign: "center", my: 2 }} >
                                    <LoadingButton loading={loading} variant='contained' onClick={() => changePage(1)}>
                                        Load More
                                    </LoadingButton>
                                </Box>
                            }
                        </Fragment>

                        :
                        <Box sx={{ textAlign: 'center' }}>
                            <Typography sx={{ fontSize: '2rem' }}>Oops... we didn't find anything that matches</Typography>
                            <Typography>Try to search for something more general, change the filters or check for spelling mistakes</Typography>
                        </Box>
                    :
                    <Box sx={{ display: 'flex', justifyContent: "center" }} >
                        <CircularProgress />
                    </Box>
                }
            </Box>
        </Layout >
    )
}

export default MyAds