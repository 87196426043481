import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { Avatar, Breadcrumbs, Button, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import productCover from '../../assets/Images/productCover.png';
import profile from '../../assets/Images/profile.jpg';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import ChatIcon from '@mui/icons-material/Chat';
import moment from 'moment'
import Layout from './../../layout/Layout';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Service } from './../../config/service';
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import { FreeMode, Navigation, Thumbs } from "swiper";
import { FavoriteBorderOutlined } from '@mui/icons-material';
import SignIn from '../../components/SignIn/SignIn';
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    button: {
        textTransform: 'none'
    },
    fontSize: '18px',
    color: '#484848',
}));


const AdDetails = () => {

    const token = localStorage.getItem('token') || ''
    const { id } = useParams()
    const navigate = useNavigate()
    const [user, setUser] = useState()
    const [adDetail, setAdDetail] = useState()
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [favAdd, setFavAdd] = useState([]);
    const [openSignInDialog, setOpenSignInDialog] = useState(false);

    const addFav = async (id) => {
        try {
            if (!token) {
                setOpenSignInDialog(true);
                return
            }
            let obj = {
                ad_Id: id
            }
            const { response } = await Service.addFav(obj, token)
            if (response === 200) {
                let array = [...favAdd]
                const index = array.findIndex(element => element === id)
                if (index === -1) {
                    array.push(id)
                } else {
                    array.splice(index, 1)
                }
                setFavAdd(array)
            }
        } catch (error) {
        }
    }

    const fetchAds = async () => {
        try {
            const { Data } = await Service.adsDetail(id, token)
            setAdDetail(Data)
            defaultFav(Data)
            fetchUser(Data.user_Id)
        }
        catch (error) {
            console.log(error)
        }
    }

    const fetchUser = async (id) => {
        try {

            const { Data } = await Service.user(id)
            setUser(Data)
        }
        catch (error) {
            console.log(error)
        }
    }

    const defaultFav = (data) => {
        try {
            let array = []
            if (data.favourite == true) {
                array.push(data._id)
            }
            console.log(array)
            setFavAdd(array)
        } catch (error) {
        }
    }

    useEffect(() => {
        fetchAds()
    }, [])

    return (
        <Layout>
            <SignIn openDialog={openSignInDialog} onClose={() => setOpenSignInDialog(false)} />

            {adDetail ?
                <Box>
                    <Breadcrumbs aria-label="breadcrumb" sx={{ fontSize: "25px", my: 2 }} >
                        <Typography sx={{ color: "#2c457a", fontSize: "18px", cursor: 'pointer' }} onClick={() => navigate('/')}>Home</Typography>
                        <Typography sx={{ color: "#2c457a", fontSize: "18px" }}> {adDetail.category_name}</Typography>
                        <Typography sx={{ color: "#2c457a", fontSize: "18px" }}> {adDetail.subcategory_name}</Typography>
                    </Breadcrumbs>
                    <Box sx={{ width: '100%', my: '7px' }}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={12} lg={7}>
                                <Swiper
                                    style={{
                                        "--swiper-navigation-color": "#fff",
                                        "--swiper-pagination-color": "#fff",
                                    }}
                                    loop={true}
                                    spaceBetween={5}
                                    navigation={true}
                                    thumbs={{ swiper: thumbsSwiper }}
                                    modules={[FreeMode, Navigation, Thumbs]}
                                    className="mySwiper2"
                                >

                                    {adDetail.images.map((image, i) => {

                                        return (
                                            <SwiperSlide>
                                                <img src={image} />
                                            </SwiperSlide>
                                        )
                                    })}

                                </Swiper>


                                <Swiper className='thumb-swiper'
                                    onSwiper={setThumbsSwiper}
                                    // loop={true}
                                    spaceBetween={45}
                                    slidesPerView={5}
                                    freeMode={true}
                                // watchSlidesProgress={true}
                                // modules={[FreeMode, Navigation, Thumbs]}
                                // className="mySwiper"
                                >
                                    {adDetail.images.map((image, i) => {
                                        return (
                                            <SwiperSlide key={i} className='all-image-swiper-wrapper'>
                                                <img src={image} />
                                            </SwiperSlide>
                                        )
                                    })}
                                </Swiper>

                            </Grid>

                            <Grid item xs={12} lg={5}>
                                <Item >
                                    <Box style={{ display: "flex", justifyContent: 'space-between' }}>
                                        <Typography noWrap sx={{ fontSize: '22px', whiteSpace: "normal" }}>{adDetail.title}</Typography>
                                        {favAdd.findIndex(e => e === id) !== -1 ?
                                            <FavoriteIcon sx={{ color: '#2c457a', cursor: 'pointer' }} onClick={() => addFav(id)} />
                                            :
                                            <FavoriteBorderOutlined sx={{ cursor: 'pointer', ':hover': { color: '#2c457a' } }} onClick={() => addFav(id)} />
                                        }
                                    </Box>
                                    <Typography sx={{ fontSize: '22px', fontWeight: 700 }} >{adDetail.price}</Typography>
                                    <Divider sx={{ m: '2%' }} />
                                    <Grid container
                                        style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Grid item xs={12} lg={8}>
                                            <Box sx={{ display: 'flex' }}  >
                                                <Grid item xs={2} lg={1} >
                                                    <LocationOnOutlinedIcon sx={{ color: '#868689' }} />
                                                </Grid>
                                                <Grid item xs={10} lg={11} >
                                                    <Typography sx={{ fontSize: '12px', mt: '2%', mx: 1 }} >{adDetail.location}</Typography>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <Box sx={{ display: 'flex' }} >
                                                <Grid item xs={2} lg={1} >
                                                    <CalendarMonthOutlinedIcon sx={{ color: '#868689' }} />
                                                </Grid>
                                                <Grid item xs={10} lg={11} >
                                                    <Typography sx={{ fontSize: '12px', mt: '3%', ml: 2 }} >
                                                        {moment(adDetail.posted_On).format('DD MMM YYYY')} </Typography>
                                                </Grid>

                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Item>
                                <br />
                                <Item sx={{ p: 3 }}>
                                    <Typography sx={{ fontSize: '18px', mb: '5%', mx: "2%", color: "#707070" }}>Seller Description</Typography>
                                    {user &&
                                        <Box sx={{ display: "flex" }}>
                                            <Avatar alt={user.name} src={user.image}
                                                sx={{ width: '80px', height: "80px", border: '3px solid #3b5998' }} />
                                            <Box sx={{ my: '1%', mx: '3%' }}>
                                                <Typography sx={{ fontSize: '18px' }}>{user.name}</Typography>
                                                <Typography sx={{ fontSize: '14px', color: '#868689' }}>
                                                    Phone Number: {user.phoneno}
                                                </Typography>
                                                <Typography sx={{ fontSize: '14px', color: '#868689' }}>
                                                    Joined:{moment(user.created_on).format('DD MMM YYYY')}
                                                </Typography>
                                                <Box sx={{ display: 'flex' }}>
                                                    <Link to={`/profile/${user._id}`}>
                                                        <Box sx={{ display: "flex", mt: 1 }}>
                                                            <Typography sx={{ fontSize: '17px', color: '#3b5998', fontWeight: '600' }}>See Profile</Typography>
                                                            <ArrowRightIcon sx={{ color: 'black' }} />
                                                        </Box>
                                                    </Link>
                                                    <Button variant="contained"
                                                        sx={{ ml: 3, bgcolor: '#3B5998', px: 3, py: 1, borderRadius: 2 }}>
                                                        <PhoneInTalkIcon sx={{ mr: 1 }} />Call</Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                    }
                                </Item>
                            </Grid>

                            <Grid item xs={12} lg={7} >
                                <Item>
                                    <Typography
                                        sx={{ fontSize: '22px', my: '1%', mx: "2%", fontWeight: '500' }}>
                                        All Details
                                    </Typography>
                                    <Grid container spacing={4} sx={{ p: 1 }}>
                                        <Grid item xs={12} lg={6} display='flex'>
                                            <Grid item xs={5}>
                                                <Typography
                                                    sx={{ my: '1%' }}>{adDetail.condition}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Typography sx={{ fontSize: '18px', my: '1%', mx: "2%" }}>
                                        Description
                                    </Typography>
                                    <Grid item xs={8} lg={10}>
                                        <Typography sx={{ fontSize: '13px', my: '1%', mx: "2%", whiteSpace: 'pre-line', wordBreak: 'break-all' }}>
                                            {adDetail.description}
                                        </Typography>
                                        {/* <Box sx={{ display: 'flex' }}>
                                <Typography sx={{ fontSize: '13px', my: '1%', mx: "2%", fontWeight: 600 }}> Read More </Typography>
                                <ArrowRightIcon sx={{ my: '0.4%' }} />
                            </Box> */}
                                    </Grid>
                                </Item>
                            </Grid>
                            <Grid item xs={12} lg={5}>
                                <Item>
                                    <Typography sx={{ fontSize: '22px', my: '1%', mx: "2%", color: "#707070", fontWeight: '700' }}>See Location</Typography>
                                    <Typography sx={{ fontSize: '16px', mx: "2%", color: "#707070" }}>{adDetail.local_area}, {adDetail.location}</Typography>
                                    <iframe
                                        width="450"
                                        height="250"
                                        frameBorder="0"
                                        referrerPolicy="no-referrer-when-downgrade"
                                        src={`https://www.google.com/maps/embed/v1/place?key= AIzaSyBDQhGZmQRu_SYO9YithPAuA2z6kQS1Sec&q=${adDetail.location}`}
                                        allowFullScreen>
                                    </iframe>
                                </Item>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                :
                <Box height={80} sx={{ width: "100%", textAlign: "center" }}>
                    <CircularProgress />
                </Box>
            }
        </Layout >
    )
}

export default AdDetails