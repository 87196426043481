import { Avatar, Box, Button, CircularProgress, Divider, FormControl, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import React, { useEffect, useRef, useState } from 'react'
import Layout from '../../layout/Layout'
import MyLocationOutlinedIcon from '@mui/icons-material/MyLocationOutlined';
import { Service } from './../../config/service';
import { useForm } from 'react-hook-form';
import useAuth from '../../hooks/useAuth';
import useGeoLocation from '../../hooks/useGeoLocation';
import { LoadingButton } from '@mui/lab';
import { ErrorToaster, SuccessToaster } from '../../components/UI/Toaster';

const EditProfile = () => {
  let obj = {
    _id: '',
    name: '',
    phoneno: '',
    email: '',
    description: '',
    address: '',
    image: ''

  }
  const location = useGeoLocation();
  const { signIn } = useAuth()
  const { register, handleSubmit, formState: { errors } } = useForm({});
  const { register: register2, handleSubmit: handleSubmit2, watch, formState: { errors: errors2 }, reset } = useForm({});
  const password = useRef({});
  password.current = watch("newpassword", "");
  const [image, setImage] = useState()
  const [user, setUser] = useState(obj)
  const [aboutLength, setAboutLength] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const token = localStorage.getItem('token') || ''

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser(prevState => ({ ...prevState, [name]: value }));
  }
  const fetchUserData = async () => {
    try {
      const { Data } = await Service.specificProfile(token);
      setUser(Data)
      setImage(Data.image)
      setAboutLength(Data.description.length)
      signIn(Data.name, token, Data.image)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchUserData()
  }, [])

  const hiddenFileInput = useRef(null);
  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const handleImage = async (e) => {
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      user.image = reader.result
      setImage(reader.result)
    }
  }

  const submitForm = async (data) => {

    setLoading(true)
    try {
      let obj = {
        name: data.name,
        description: data.description,
        address: data.address,
        image: image

      }
      const { response, status, message, Data } = await Service.userProfileUpdate(obj, token)
      if (status === true && response === 200) {
        signIn(user.name, token, user.image)
        SuccessToaster(message)
      }
    }
    catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }


  }

  const changePasswordForm = async (data) => {
    setLoading2(true)
    try {
      let obj = {
        password: data.currentPassword,
        new_password: data.confirmPassword,
        confirmPassword: data.confirmPassword
      }
      const { response, message } = await Service.change_password(obj, token)
      if (response === 200) {
        reset()
        SuccessToaster(message)
      } else {
        ErrorToaster(message)
      }
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading2(false)
    }
  }
  const getLocation = () => {
    try {
      let { lat, lng } = location.coordinates

      const { response, status, message, Data } = Service.getLocation(lat, lng)

    } catch (error) {
      console.trace('Inside Catch => ', error);
    }
  }
  return (

    <Layout>
      {
        user._id.length > 0 ?
          <Box sx={{ bgcolor: 'white', width: "100%" }}>
            <Box component="form" onSubmit={handleSubmit(submitForm)}>
              <Box sx={{ mb: 2, width: '80px', cursor: 'pointer' }}>
                <Avatar
                  onClick={handleClick}
                  alt="..."
                  src={user.image}
                  sx={{ width: 56, height: 56, border: '3px solid #3b5998', margin: 'auto', my: '5px' }} />
                <input type="file"
                  accept="image/*"
                  ref={hiddenFileInput}
                  onChange={handleImage}

                  style={{ display: 'none' }}
                />
              </Box>
              <Grid container spacing={2} >
                <Grid item xs={12} lg={3.5} md={6} sx={{ m: 1 }}>
                  <FormControl fullWidth >
                    <TextField
                      placeholder='Name'
                      name="name"

                      value={user.name || ''}
                      {...register('name', {
                        required: "Please Enter name",
                      })}
                      onChange={handleChange}
                      InputProps={{
                        style: {
                          marginBottom: '15px'
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonOutlineOutlinedIcon />
                          </InputAdornment>
                        ),
                      }} />
                    {errors.name && <span role="alert" style={{ color: 'red' }}>{errors.name.message}</span>}
                  </FormControl >

                  <FormControl fullWidth >
                    <TextField disabled
                      value={user.phoneno || ''}
                      name="phoneno"
                      placeholder='Phone Number'
                      {...register("phoneno", {
                        pattern: {
                          value: /^((\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$/,
                          message: "Entered value does not match phone no format"
                        }
                      })}
                      onChange={handleChange}
                      InputProps={{
                        style: {
                          marginBottom: '15px'
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocalPhoneOutlinedIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {errors.phoneno && <span role="alert" style={{ color: 'red' }}>{errors.phoneno.message}</span>}
                  </FormControl>
                  <FormControl fullWidth >
                    <TextField
                      disabled
                      name="email"
                      value={user.email || ''}
                      placeholder='Email'
                      {...register("email", {
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Entered value does not match email format"
                        }
                      })}
                      onChange={handleChange}
                      InputProps={{
                        style: {
                          marginBottom: '15px'
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailOutlinedIcon />
                          </InputAdornment>
                        ),
                      }} />
                    {errors.email && <span role="alert" style={{ color: 'red' }}>{errors.email.message}</span>}
                  </FormControl >

                </Grid>
                <Grid item lg={8} xs={12} md={6} sx={{ m: 1 }}>
                  <FormControl fullWidth>
                    <TextField multiline rows={5}
                      inputProps={{ maxLength: 400 }}
                      placeholder="About me (Optional)"
                      value={user.description}
                      name="description"
                      {...register('description', {

                      })}
                      onChange={(event) => { setAboutLength(event.target.value.length); handleChange(event) }} />
                  </FormControl>
                  <Typography

                    sx={{
                      color: '#898B8F', fontSize: '16px', my: 1
                    }}>
                    {aboutLength}/400
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              <Grid container sx={{ py: 3 }}>
                <Grid item lg={3.5} xs={12} sx={{ mx: 1 }}>
                  <FormControl fullWidth>
                    <TextField placeholder="Address" size='small' name="address"
                      value={user.address || ''}
                      {...register("address")}
                      onChange={handleChange}
                      InputProps={{
                        style: {
                          marginTop: '15px'
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocationOnOutlinedIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end" onClick={getLocation}>
                            <MyLocationOutlinedIcon sx={{ cursor: 'pointer' }} />
                          </InputAdornment>
                        )
                      }} />
                  </FormControl>
                  {errors.description && <span role="alert" style={{ color: 'red' }}>{errors.description.message}</span>}
                </Grid>
                <Grid item lg={8.2} xs={12} sx={{ textAlign: "center" }}>
                  <LoadingButton loading={loading} type='submit' variant='contained'
                    sx={{ float: 'right', bgcolor: '#3B5998', p: 1, my: '10px', fontSize: '12px' }}
                  > Save Changes</LoadingButton>

                </Grid>
              </Grid>
              <Divider />
            </Box>
            <Box sx={{ m: 1 }}>
              <Typography variant='h6' sx={{ mt: 1 }}><strong>Change Password</strong></Typography>
              <Box component="form" onSubmit={handleSubmit2(changePasswordForm)} >
                <FormControl fullWidth sx={{ my: 2 }}>
                  <TextField
                    error={errors2?.currentPassword?.message && (true)}
                    type='password'
                    label="Current Password"
                    variant="outlined"
                    {...register2("currentPassword", {
                      required: "please enter current password",
                      message: 'Please Enter Current Password'

                    })} />

                </FormControl>
                {errors2.currentPassword && <span role="alert" style={{ color: 'red' }}>{errors2.currentPassword.message}</span>}
                <FormControl fullWidth sx={{ my: 2 }}>
                  <TextField
                    error={errors2?.newpassword?.message && (true)}
                    type='password'
                    label="New Password"
                    variant="outlined"

                    {...register2("newpassword", {
                      required: "please enter new password",
                      minLength: {
                        value: 6,
                        message: "min length is 6"
                      }
                    })}
                  />

                </FormControl>
                {errors2.newpassword && <span style={{ color: 'red' }} role="alert">{errors2.newpassword.message}</span>}
                <FormControl fullWidth sx={{ my: 2 }}>
                  <TextField
                    error={errors2?.confirmPassword?.message && (true)}
                    label="Confirm Password"
                    variant="outlined"
                    type='password'

                    {...register2('confirmPassword', {
                      required: "please enter again",
                      validate: value =>
                        value === password.current || "The passwords do not match"
                    })} />
                </FormControl>
                {errors2.confirmPassword && <span role="alert" style={{ color: 'red' }}>{errors2.confirmPassword.message}</span>}
                <Grid container >
                  <Grid item xs={12}>
                    <LoadingButton loading={loading2} type="submit" variant="contained"
                      sx={{ float: { md: "right", xs: "right" }, bgcolor: '#3B5998', p: 1, my: 1, fontSize: '12px' }}>
                      Update Password</LoadingButton>
                  </Grid>
                </Grid>

              </Box>
            </Box>
          </Box>
          :
          <Box sx={{ width: "100%", textAlign: "center" }}>
            <CircularProgress />
          </Box>
      }

    </Layout >
  )
}

export default EditProfile