import { Apis, get, post, put } from '.';

export const Service = {
    signUp: async (obj) => {
        let result = await post(Apis.signUp, obj);
        if (result.status === 201) return result.data;
        else throw result;
    },
    verifySignup: async (obj) => {
        let result = await post(Apis.verifySignup, obj);
        if (result.status === 201) return result.data;
        else throw result;
    },
    resendOTP: async (obj) => {
        let result = await post(Apis.resendOTP, obj);
        if (result.status === 201) return result.data;
        else throw result;
    },
    signIn: async (obj) => {
        let result = await post(Apis.signIn, obj);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getCategory: async () => {
        let result = await get(Apis.getCategory);
        if (result.status === 201) return result.data;
        else throw result;
    },
    getSubCategory: async (id) => {
        let result = await get(Apis.getSubCategory + `?cat_id=${id}`);
        if (result.status === 201) return result.data;
        else throw result;
    },
    insertAds: async (obj, token) => {
        let result = await post(Apis.insertAds, obj, token);
        if (result.status === 201) return result.data;
        else throw result;
    },
    forgetPassword: async (obj) => {
        let result = await post(Apis.forgetPassword, obj);
        console.log("🚀 ~ file: service.js ~ line 41 ~ forgetPassword: ~ result", result)
        if (result.status === 201) return result.data;
        else throw result;
    },
    resetPassword: async (obj) => {
        let result = await post(Apis.resetPassword, obj);

        if (result.status === 201) return result.data;
        else throw result;
    },
    recentAds: async (page, token) => {
        let result = await get(Apis.recentAds + '?page=' + page + '&size=20', token);
        if (result.status === 200) return result.data;
        else throw result;
    },
    insertImage: async (obj, token) => {
        let result = await post(Apis.insertImage, obj, token);
        if (result.status === 201) return result.data;
        else throw result;
    },
    user: async (id) => {
        let result = await get(Apis.user + '/' + id);
        if (result.status === 201) return result.data;
        else throw result;
    },
    allCities: async () => {
        let result = await get(Apis.allCities);
        if (result.status === 201) return result.data;
        else throw result;
    },
    getSub_Division: async (id) => {
        let result = await get(Apis.getSub_Division + `?city_Id=${id}`);
        if (result.status === 201) return result.data;
        else throw result;
    },
    myAds: async (status, title, page, token) => {
        let result = await get(Apis.myAds + `?status=${status}&search=${title}&page=${page}&size=10`, token);
        if (result.status === 200) return result.data;
        else throw result;
    },
    adsDetail: async (id, token) => {
        let result = await get(Apis.adsDetail + `/${id}`, token);
        if (result.status === 201) return result.data;
        else throw result;
    },
    userAds: async (id, token) => {
        let result = await get(Apis.userAds + `?user_Id=${id}`, token);
        if (result.status === 201) return result.data;
        else throw result;
    },
    specificProfile: async (token) => {
        let result = await get(Apis.specificProfile, token);
        if (result.status === 200) return result.data;
        else throw result;
    },
    userProfileUpdate: async (obj, token) => {

        let result = await put(Apis.userProfileUpdate, obj, token);
        if (result.status === 201) return result.data;
        else throw result;
    },
    addFav: async (obj, token) => {
        let result = await post(Apis.addFav, obj, token);
        if (result.status === 200) return result.data;
        else throw result;
    },
    user_fav_Ads: async (token) => {
        let result = await get(Apis.user_fav_Ads, token);
        if (result.status === 200) return result.data;
        else throw result;
    },
    change_password: async (obj, token) => {
        let result = await post(Apis.change_password, obj, token);
        if (result.status === 200) return result.data;
        else throw result;
    },
    searchAds: async (title, city, gPrice, lPrice, category, subdivision, page, token) => {
        let result = await get(Apis.searchAds + '?main_search=' + title + '&city1=' + city + '&g_price1=' + gPrice + '&l_price1=' + lPrice + '&category1=' + category + '&subdivision_id1=' + subdivision + '&page=' + page + '&size=30', token);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getLocation: async (lat, lng) => {
        let result = await get(Apis.getLocation + `?lat1=${lat}3&lng1=${lng}`);
        if (result.status === 200) return result.data;
        else throw result;
    },
    contactForm: async (obj) => {
        let result = await post(Apis.contactForm, obj);
        if (result.status === 200) return result.data;
        else throw result;
    },
    sideFilter: async () => {
        let result = await get(Apis.sideFilter);
        if (result.status === 200) return result.data;
        else throw result;
    },
    autoCompleteDropDown: async (search) => {
        let result = await get(Apis.autoCompleteDropDown + '?search=' + search);
        if (result.status === 200) return result.data;
        else throw result;
    },
    changeAdStatus: async (id, data, token) => {
        let result = await put(Apis.changeAdStatus + '?ad_id=' + id, data, token);
        if (result.status === 201) return result.data;
        else throw result;
    },
    updateAds: async (id, data, token) => {
        let result = await put(Apis.updateAds + id, data, token);
        if (result.status === 201) return result.data;
        else throw result;
    },


}