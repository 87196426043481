import { Add, ChatOutlined, FavoriteBorderOutlined, KeyboardArrowDown, NotificationsActiveOutlined, SearchOutlined, ArrowDropDownOutlined, LogoutOutlined } from '@mui/icons-material';
import { Avatar, Box, Button, CardMedia, Divider, Grid, IconButton, ListItemIcon, MenuItem, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Images from '../assets/Images/Images';
import Signup from '../components/SignUp/SignUp';
import SignIn from '../components/SignIn/SignIn';
import { Link, useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import useAuth from '../hooks/useAuth';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import Otp from '../components/Otp/Otp';
import { AiOutlineMenu } from "react-icons/ai";
import PersonIcon from '@mui/icons-material/Person';

function Header() {

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { user, signOut } = useAuth();

  const [openSignUpDialog, setOpenSignUpDialog] = useState(false);
  const [openSignInDialog, setOpenSignInDialog] = useState(false);
  const [openOtpDialog, setOpenOtpDialog] = useState(false);
  const [timer, setTimer] = useState(false);

  // *For Save User Register Data
  const [userRegisterData, setUserRegisterData] = useState();
  const [menutoggle, setMenutoggle] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const goToFavourite = () => {
    try {
      if (!user) {
        setOpenSignInDialog(true)
      } else {
        navigate('/favourite')
      }
    } catch (error) {
      console.log('file: Header.js => line 35 => goToNotification => error', error)
    }
  }


  return (
    <Box sx={{ paddingY: 1 }}>

      {/* ========== Sign Up Dialog Box ========== */}
      <Signup openDialog={openSignUpDialog} onClose={() => setOpenSignUpDialog(false)} openOtpDialog={() => { setOpenOtpDialog(true); setTimer(true) }} registerData={(data) => setUserRegisterData(data)} />

      {/* ========== Sign In Dialog Box ========== */}
      <SignIn openDialog={openSignInDialog} onClose={() => setOpenSignInDialog(false)} />

      {/* ========== OTP Dialog Box ========== */}
      <Otp openDialog={openOtpDialog} onClose={() => setOpenOtpDialog(false)} data={userRegisterData} startTimer={timer} />

      <Grid container spacing={0.2} justifyContent="space-between" >


        <Grid item xs={4.5} md={5.6}>
          <Box sx={{ display: 'flex' }} >

            <Link to='/'>
              <CardMedia
                component="img"
                height="44px"
                sx={{ width: "41px !important" }}
                image={Images.pic1}
                alt="camera"

              /></Link>
            <Typography sx={{ m: 2, fontSize: "12px", display: { md: "flex", xs: "none" } }} >
              Pakistan
            </Typography>

            <Typography sx={{ paddingY: 1.5, fontSize: "10px" }}>
              <KeyboardArrowDown />
            </Typography>
          </Box>

        </Grid>
        <Grid item xs={2.5} md={4}>
          <Box sx={{ display: "flex", justifyContent: "end" }}>


            {/* <MenuItem disableRipple sx={{ textAlign: 'center', display: 'block', '&:hover': { bgcolor: 'transparent !important' } }}>
              <SearchOutlined sx={{ color: '#707070', fontSize: "15px" }} />


              <Typography textAlign="center" sx={{ color: '#707070', fontSize: "8px" }}>SEARCHES</Typography>
            </MenuItem> */}

            {/* <MenuItem disableRipple
                sx={{
                  textAlign: 'center', display: 'block',
                  alignItems: "center",
                  '&:hover': { bgcolor: 'transparent !important' }
                }}
                onClick={() => goToFavourite()}
              >
                < FavoriteBorderOutlined sx={{ color: '#707070', fontSize: "15px" }} />
                <Typography textAlign="center" sx={{ color: '#707070', fontSize: "8px", textAlign: "center", }}>FAVOURITE</Typography>
              </MenuItem> */}



            {/* <Box sx={{ display: { md: "none", xs: "block" } }}>
                <MenuItem disableRipple
                  sx={{
                    textAlign: 'center', display: 'block',
                    alignItems: "center",
                    '&:hover': { bgcolor: 'transparent !important' }
                  }}
                  onClick={() => goToFavourite()}
                >
                  < FavoriteBorderOutlined sx={{ color: '#707070', fontSize: "15px" }} />
                  <Typography textAlign="center" sx={{ color: '#707070', fontSize: "8px", textAlign: "center", }}>FAVOURITE</Typography>
                </MenuItem>



              </Box> */}



            {user
              ?
              <>
                <Box sx={{ ml: 2, mt: 1, display: 'flex', cursor: 'pointer' }} onClick={(event) => setAnchorEl(event.currentTarget)}>
                  <Avatar alt={user.name} src={user.image}
                    sx={{ width: 30, height: 30, border: '3px solid #3b5998' }} />
                  <Typography sx={{ fontSize: '14px', mx: 1, my: 0.5, display: { md: "block", xs: "none" } }}>{user.name} </Typography>
                  <ArrowDropDownOutlined sx={{ py: 0.2, ml: 1 }}
                    size="small"
                    aria-haspopup="true"
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined} />
                </Box>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={() => setAnchorEl(null)}
                  onClick={() => setAnchorEl(null)}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      width: 250,
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {

                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                        alignItems: "right",
                        '&:hover': { bgcolor: 'transparent !important' },
                      },

                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <Box sx={{ padding: '15px', display: 'flex', }}>
                    <ListItemIcon >
                      <Avatar alt={user.name} src={user.image}
                        sx={{ width: 45, height: 45, border: '3px solid #3b5998', }} />
                    </ListItemIcon>
                    <Box >
                      <Typography>Hello,</Typography>
                      <Typography sx={{ fontWeight: 600 }}>{user.name}</Typography>
                    </Box>

                  </Box>
                  <Divider />
                  <MenuItem component={Link} to='/edit/profile' >
                    <ListItemIcon sx={{ color: 'black' }}>
                      <PersonIcon />
                    </ListItemIcon>
                    Profile
                  </MenuItem>

                  <MenuItem component={Link} to='/myads'>
                    <ListItemIcon sx={{ color: 'black' }} >
                      <ListAltOutlinedIcon />
                    </ListItemIcon>
                    My Ads
                  </MenuItem>

                  <MenuItem component={Link} to='/favourite'>
                    <ListItemIcon sx={{ color: 'black' }} >
                      <FavoriteBorderOutlined />
                    </ListItemIcon>
                    My Favourites
                  </MenuItem>

                  
                  <Divider />
                  <MenuItem onClick={() => signOut()}>
                    <ListItemIcon sx={{ color: 'black' }} >
                      <LogoutOutlined />
                    </ListItemIcon>
                    Logout
                  </MenuItem>

                </Menu>
                <MenuItem disableRipple sx={{ textAlign: 'right', display: 'block', alignItems: "right", paddingTop: 1.5, '&:hover': { bgcolor: 'transparent !important' } }}>
                  <Button component={Link} to='/sell' variant="contained" startIcon={<Add />} sx={{ fontSize: "12px", backgroundColor: "#3B5998", borderRadius: "12%", }}>
                    Sell
                  </Button>
                </MenuItem>
              </>
              :
              <>
                <MenuItem disableRipple sx={{ textAlign: 'center', display: 'block', alignItems: "center", '&:hover': { bgcolor: 'transparent !important' } }}>
                  <Button onClick={() => setOpenSignInDialog(true)} sx={{ color: '#707070', fontSize: "14px", fontWeight: 700, paddingTop: 1.3 }}>
                    Sign in
                  </Button>
                </MenuItem>

                <MenuItem disableRipple sx={{ textAlign: 'center', display: 'block', alignItems: "center", '&:hover': { bgcolor: 'transparent !important' } }}>
                  <Button onClick={() => setOpenSignUpDialog(true)} sx={{ color: '#707070', fontSize: "14px", fontWeight: 700, paddingTop: 1.3 }}>
                    Sign up
                  </Button>

                </MenuItem>



                <MenuItem disableRipple sx={{ textAlign: 'center', display: 'block', alignItems: "center", paddingTop: 1.5, '&:hover': { bgcolor: 'transparent !important' } }}>
                  <Button onClick={() => setOpenSignInDialog(true)} variant="contained" startIcon={<Add />} sx={{ fontSize: "12px", backgroundColor: "#3B5998", borderRadius: "12%" }}>
                    Sell
                  </Button>
                </MenuItem>
              </>
            }




          </Box>



        </Grid>

      </Grid>



      {/* 
      <Typography component="div" sx={{
        display: {
          xs: "block",
          md: "none",
          textAlign: "right",

        },
        width: '100%',
      }}>

        <IconButton onClick={() => { setMenutoggle(!menutoggle) }}  >
          <AiOutlineMenu style={{
            fontSize: "30px",

            cursor: "pointer",


          }} />
        </IconButton>
      </Typography> */}





      {/* {
        menutoggle &&
        <Grid container spacing={0.2} sx={{ display: { md: "none", xs: "block" }, }}>

          <Grid item xs={2}>
            <Box sx={{}}>

              {user
                ?
                <>
                  <Box sx={{ ml: 2, mt: 1, display: 'flex', cursor: 'pointer' }} onClick={(event) => setAnchorEl(event.currentTarget)}>
                    <Avatar alt={user.name} src={user.image}
                      sx={{ width: 30, height: 30, border: '3px solid #3b5998' }} />

                    <ArrowDropDownOutlined sx={{ py: 0.2, ml: 1 }}
                      size="small"
                      aria-haspopup="true"
                      aria-controls={open ? 'account-menu' : undefined}
                      aria-expanded={open ? 'true' : undefined} />
                  </Box>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={() => setAnchorEl(null)}
                    onClick={() => setAnchorEl(null)}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: 'visible',
                        width: 250,
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {

                          ml: -0.5,
                          mr: 1,
                        },
                        '&:before': {
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: 'background.paper',
                          transform: 'translateY(-50%) rotate(45deg)',
                          zIndex: 0,
                        },

                      },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  >
                    <Box sx={{ padding: '15px', display: 'flex' }}>
                      <ListItemIcon >
                        <Avatar alt={user.name} src={user.image}
                          sx={{ width: 45, height: 45, border: '3px solid #3b5998' }} />
                      </ListItemIcon>
                      <Box>
                        <Typography>Hello,</Typography>
                        <Typography sx={{ fontWeight: 600 }}>{user.name}</Typography>
                      </Box>

                    </Box>
                    <Divider />
                    <MenuItem component={Link} to='/edit/profile' >
                      <ListItemIcon sx={{ color: 'black' }}>
                        <ListAltOutlinedIcon />
                      </ListItemIcon>
                      Profile
                    </MenuItem>

                    <MenuItem component={Link} to='/myads'>
                      <ListItemIcon sx={{ color: 'black' }} >
                        <ListAltOutlinedIcon />
                      </ListItemIcon>
                      My Ads
                    </MenuItem>
                    <MenuItem disableRipple
                      sx={{
                        textAlign: 'left', display: 'block',
                        alignItems: "left",
                        '&:hover': { bgcolor: 'transparent !important' },
                        display: "flex",
                        my: 0.2
                      }}
                      onClick={() => goToFavourite()}
                    >

                      < FavoriteBorderOutlined sx={{ color: 'black', fontSize: "20px" }} />
                      <Typography textAlign="center" sx={{ color: 'black', fontSize: "13px", textAlign: "left", ml: 2 }}>FAVOURITE</Typography>
                    </MenuItem>
                    <Divider />


                    <MenuItem onClick={() => signOut()}>
                      <ListItemIcon sx={{ color: 'black' }} >
                        <LogoutOutlined />
                      </ListItemIcon>
                      Logout
                    </MenuItem>
                  </Menu>

                </>
                :
                <>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={() => setAnchorEl(null)}
                    onClick={() => setAnchorEl(null)}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: 'visible',
                        width: 250,
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {

                          ml: -0.5,
                          mr: 1,
                        },
                        '&:before': {
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: 'background.paper',
                          transform: 'translateY(-50%) rotate(45deg)',
                          zIndex: 0,
                        },

                      },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  >





                    <MenuItem disableRipple sx={{ textAlign: 'center', display: 'block', alignItems: "center", '&:hover': { bgcolor: 'transparent !important' } }}>
                      <Button onClick={() => setOpenSignInDialog(true)} sx={{ color: '#707070', fontSize: "14px", fontWeight: 700, paddingTop: 1.3 }}>
                        Sign in
                      </Button>
                    </MenuItem>

                    <MenuItem disableRipple sx={{ textAlign: 'center', display: 'block', alignItems: "center", '&:hover': { bgcolor: 'transparent !important' } }}>
                      <Button onClick={() => setOpenSignUpDialog(true)} sx={{ color: '#707070', fontSize: "14px", fontWeight: 700, paddingTop: 1.3 }}>
                        Sign up
                      </Button>

                    </MenuItem>
                  </Menu>

                </>
              }




            </Box>

          </Grid>

        </Grid>


      } */}


    </Box >
  );
}

export default Header;