import { Autocomplete, Box, Breadcrumbs, Button, Divider, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import Layout from '../../layout/Layout';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Service } from './../../config/service';
import useAuth from '../../hooks/useAuth';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import { ErrorToaster, SuccessToaster } from '../../components/UI/Toaster';



const EditForm = () => {

  const token = localStorage.getItem('token') || ''
  const { register, handleSubmit, formState: { errors }, setValue, clearErrors } = useForm({});
  const { id } = useParams()
  const [post, setPost] = useState(false)
  const [cities, setCities] = useState([]);
  const [division, setDivision] = useState([]);
  const [areaEnable, setAreaEnable] = useState(true);
  const [adsData, setadsData] = useState([]);

  const fetchCities = async () => {
    const { Data } = await Service.allCities()
    setCities(Data)
  }
  const fetchDivision = async (id) => {
    const { Data } = await Service.getSub_Division(id)
    setDivision(Data)
  }


  let navigate = useNavigate();
  const hiddenFileInput = useRef(null);
  const [images, setImages] = useState([]);
  const [renderImages, setRenderImages] = useState([]);
  const [titleLength, setTitleLength] = useState(0);

  const { signOut } = useAuth()

  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  const handleLength = (event) => {
    setTitleLength(event.target.value.length)
  }
  const handleImage = async (e) => {
    let files = e.target.files;
    for (var i = 0; i < files.length; i++) {
      let file = files[i];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        let fileInfo = {
          image: reader.result,
        };
        const { Data } = await Service.insertImage(fileInfo, token)
        setImages(images => [...images, Data]);
      }
    }
  }

  const removeImage = (i) => {

    const filteredArray = renderImages.filter((image, index) => index !== i);
    setRenderImages(filteredArray)
    const filteredUrlArray = images.filter((image, index) => index !== i);
    setImages(filteredUrlArray)
  }
  const handleLocation = (value) => {
    setValue("location", value.name)
    fetchDivision(value._id);
    clearErrors('location');
    setAreaEnable(false)

  }
  const submitForm = async (adsdata) => {

    setPost(true)
    try {
      let merged = { ...adsdata, category_Id: "", images: images }
      console.log("🚀 ~ file: EditForm.js ~ line 90 ~ submitForm ~ merged", merged)
      const { response, status, message, Data } = await Service.updateAds(id, merged, token)

      if (status === true && response === 200) {

        SuccessToaster(message)
        navigate(`/`)
      }


    } catch (error) {
      setPost(false)
      ErrorToaster(error)
      if ("Invalid Token" === error) {
        setTimeout(() => {
          signOut()
        }, "3000")
      }

    }

  }
  const [sortLocation, setSortLocation] = useState();
  const [sortLocalarea, setsortLocalarea] = useState();
  const [test, settest] = useState(false);
  console.log("🚀 ~ file: EditForm.js ~ line 114 ~ EditForm ~ test", test)
  const fetchAds = async () => {

    try {
      const { Data } = await Service.adsDetail(id, token)
      console.log("🚀 ~ file: EditForm. line 115 ~ fetchAds ~ Data", Data)
      setadsData(Data)
      setValue("title", Data?.title)
      setValue("description", Data?.description)
      setValue("price", Data?.price)
      let location = Data.location.split(",")
      let sortedLocation = cities.filter((item) => item?.name === location[1])
      setSortLocation(sortedLocation[0])
      console.log("🚀 ~ file: EditForm.j ~ line 120 ~ fetchAds ~ sortedLocation[0]", sortedLocation[0])
      setImages([...images, ...Data.images]);

      settest(true)

    }
    catch (error) {
      console.log(error)
    }
  }



  useEffect(() => {
    fetchAds()
    fetchCities()
  }, [])


  return (
    <Layout>
      <Box p={4} sx={{ bgcolor: "#F7F8F8" }}>
        <Box role="presentation" sx={{ px: 7 }}>
          <Breadcrumbs aria-label="breadcrumb" >
            <Link underline="hover" color="inherit" to="/">
              Home
            </Link>
            <Link
              underline="hover"
              color="inherit"
              to="/sell"
            >
              Sell
            </Link>
            <Typography color="inherit" >{adsData?.title}</Typography>
          </Breadcrumbs>
        </Box>
        <Box p={4}>
          <Typography sx={{ color: "#484848", fontWeight: "80%", fontSize: "25px", px: 3 }}>
            <strong>POST YOUR AD</strong>
          </Typography>
        </Box>


        <form onSubmit={handleSubmit(submitForm)}>
          <Box px={7} >
            <Button onClick={handleClick} sx={{ bgcolor: "#3B5998", height: "40%", width: "100%", display: "flex", justifyContent: "center", mb: 4 }}>
              <Typography sx={{ py: 6, color: "#FFFFFF" }}>
                <PhotoSizeSelectActualIcon sx={{ fontSize: '57px', mr: 2 }} />
                <AddCircleIcon sx={{ fontSize: '37px', mr: 2, mb: 1 }} />
              </Typography>
              <Typography sx={{ py: 6, color: "#FFFFFF", mt: 1.5, fontWeight: 300 }}>Add Images</Typography>
            </Button>
            <input type="file" multiple
              accept="image/*"
              ref={hiddenFileInput}
              onChange={handleImage}
              style={{ display: 'none' }}
            />
            {errors.image && <span role="alert" style={{ color: 'red' }}>{errors.image.message}</span>}
            <Box sx={{ display: 'flex' }}>

              {images.map((url, i) => (
                <Box key={i}>
                  <img src={url} alt="..." width='100px' height='100px' style={{ padding: 7 }} />

                  <IconButton component="span"
                    disableRipple
                    sx={{
                      position: 'relative',
                      left: '-19%',
                      bottom: "65%",
                      bgcolor: '#F2951F', padding: "0px"
                    }} onClick={() => removeImage(i)}>

                    <CloseIcon sx={{ color: 'white', fontSize: '15px' }} />
                  </IconButton>
                  {i === 0 &&
                    <Typography sx={{ bgcolor: '#3B5998', color: "white", width: '100px', textAlign: 'center', position: 'relative', bottom: '22%', marginLeft: '6px', opacity: '0.9' }}>Cover</Typography>
                  }

                </Box>
              ))}
            </Box>

            <Typography
              sx={{ color: "#484848", fontWeight: 600, fontSize: "20px", mb: 2 }}>

              Ad Title

            </Typography>
            <FormControl fullWidth>
              <TextField
                defaultValue={adsData?.title || ''}
                variant="outlined" sx={{ width: '100%', mb: '6px' }}
                inputProps={{ maxLength: 70 }}
                {...register('title', {
                  required: "Please Enter Ad Title",
                })}
                onChange={handleLength}
              />
            </FormControl>
            {errors.title && <span role="alert" style={{ color: 'red' }}>{errors.title.message}</span>}
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{ fontSize: "13px", color: '#707070', paddingBottom: 4 }}>
                Mention the key features of your item (e.g. brand, model, age, type)
              </Typography>
              <Typography sx={{ fontSize: '12px' }}>
                {titleLength} /70
              </Typography>
            </Box>
            <Typography
              sx={{ color: "#484848", fontWeight: 600, fontSize: "20px", mb: 2 }}>
              Description
            </Typography>
            <TextField
              defaultValue={adsData?.description || ''}
              variant="outlined" multiline rows={5}
              {...register('description', {
                required: "Please Enter description",

              })} fullWidth sx={{ width: '100%', borderRadius: 4, mb: '6px' }} />
            {errors.description && <span role="alert" style={{ color: 'red' }}>{errors.description.message}</span>}
            <Typography sx={{ fontSize: "13px", color: '#707070', paddingBottom: 4 }}>
              Include condition, features and reason for selling
            </Typography>


            <Divider />

            <Typography sx={{ color: "#484848", fontWeight: 600, fontSize: "20px", paddingY: 2 }}>

              Set A Price

            </Typography>
            <TextField
              defaultValue={adsData?.price || ''}
              type='number' label="Your Price Here" variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <IconButton>
                      <LocalOfferOutlinedIcon sx={{ fontSize: '27px', color: "#3B5998" }} />
                    </IconButton>
                  </InputAdornment>
                )
              }}

              {...register('price', {
                required: "Please Enter price",

              })}

              sx={{ width: '100%', mb: '6px' }} >   </TextField>
            {errors.price && <span role="alert" style={{ color: 'red' }}>{errors.price.message}</span>}

            <Typography sx={{ color: "#484848", fontWeight: 600, fontSize: "20px", paddingY: 2 }}>

              YOUR AD'S LOCATION

            </Typography>
            <Box sx={{ display: "flex", justifyContent: 'space-between' }}>
              {test &&
                <Box sx={{ width: '100%' }}>
                  <FormControl fullWidth>
                    <Autocomplete
                      getOptionLabel={(option) => option.name || ""}
                      defaultValue={sortLocation ? sortLocation : ""}
                      disablePortal
                      id="combo-box-demo"
                      options={cities}

                      // getOptionLabel={(option) => option.name}
                      sx={{ mr: 1 }}
                      {...register('location', {
                        required: "Please select location"
                      })}
                      onChange={(event, value) => { handleLocation(value) }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}
                        >
                          {option.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <>
                          <TextField
                            {...params}
                            placeholder='Location'
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <AddLocationAltOutlinedIcon />
                                </InputAdornment>
                              )

                            }}
                          />
                        </>
                      )}
                    />
                  </FormControl>
                  {errors.location && <span role="alert" style={{ color: 'red' }}>{errors.location.message}</span>}
                </Box>
              }

              <Box sx={{ width: '100%' }}>
                <FormControl fullWidth>
                  <Autocomplete
                    disabled={areaEnable}
                    disablePortal
                    id="combo-box-demo"
                    options={division}
                    getOptionLabel={(option) => option.Subdivison_name || ""}

                    {...register('local_area', {
                      required: "Please select local area"
                    })}
                    onChange={(event, value) => { setValue("local_area", value.Subdivison_name); clearErrors('local_area') }}

                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}
                      >
                        {option.Subdivison_name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <>
                        <TextField

                          {...params}
                          placeholder='Area'
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <AddLocationAltOutlinedIcon />
                              </InputAdornment>
                            )
                          }}
                        />
                      </>
                    )}
                  />
                </FormControl>

                {errors.local_area && <span role="alert" style={{ color: 'red' }}>{errors.local_area.message}</span>}

              </Box>

            </Box>
            <Box >
              <Button disabled={post} type='submit' variant="contained" sx={{ width: "35.9%", p: 1.5, backgroundColor: "#3B5998", marginY: 4, borderRadius: 2.4 }}>Update Now</Button>
            </Box>
          </Box>
        </form>

      </Box >

    </Layout >
  )
}

export default EditForm