import { Box, Button, CardMedia, Dialog, DialogContent, DialogContentText, Slide, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { Link } from 'react-router-dom';
import Images from '../../assets/Images/Images';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AdPost = ({ openDialog, onClose }) => {

  return (

    <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"

      PaperProps={{
        style: {
          backgroundColor: "#F9F9F9",
          boxShadow: "0px 1px 5px #00000012",
          borderRadius: '14px',
          width: '380px'
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          my: 1,
          mx: '17px'
        }}>
        <CloseIcon onClick={onClose} sx={{ color: '#898B8F', fontSize: '28px', cursor: 'pointer' }} />
      </Box>
      <DialogContent sx={{ textAlign: "center" }} >
        <CardMedia
          component="img"
          height="90px"
          sx={{ width: "90px !important", ml: 15, my: 2 }}
          image={Images.thumbsup}
          alt="..."
        />
        <Typography
          sx={{
            fontSize: '18px',
            color: '#484848', p: 1,
            textAlign: 'center'
          }}>
          <strong>Your Ad has been uploaded successfully!</strong>
        </Typography>
        <Typography
          sx={{
            fontSize: '17px',
            color: '#484848', p: 1,
            textAlign: 'center',
            color: "#898B8F"
          }}>
          Your Ad will be reachable to thousands of buyers
        </Typography>
        <Button variant='contained' component={Link} to="/" sx={{ my: 1, px: 3, py: 1, borderRadius: 2, width: '100%', bgcolor: "#3b5998" }}>
          <strong>OK</strong></Button>
      </DialogContent>
    </Dialog>


  )
}
export default AdPost