import { Box, Button, Dialog, DialogContent, DialogContentText, Typography, Slide, TextField } from '@mui/material';
import React, { Fragment } from 'react'
import { useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import { MailOutline } from '@mui/icons-material';
import 'react-toastify/dist/ReactToastify.css';
import Images from '../../assets/Images/Images';
import { Service } from '../../config/service';
import { ErrorToaster, SuccessToaster } from '../UI/Toaster';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Forget = ({ openDialog, onClose }) => {

  const { register, handleSubmit, formState: { errors } } = useForm({});

  const submitForm = async (data) => {
    try {
      const { response, status, message } = await Service.forgetPassword(data)
      if (status === true && response === 200) {
        onClose()
        SuccessToaster(message)
      } else {
        ErrorToaster(message)
      }

    } catch (error) {
      ErrorToaster(error)
    }
  }
  return (
    <Fragment>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"

        PaperProps={{
          style: {
            backgroundColor: "#F9F9F9",
            boxShadow: "0px 1px 5px #00000012",
            borderRadius: '14px',
            width: '380px'
          },
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 1, mx: '17px' }}>
          <CloseIcon onClick={onClose} sx={{ color: '#898B8F', fontSize: '28px', cursor: 'pointer' }} />
        </Box>
        <Box sx={{ display: 'block', mx: 'auto', my: '-18px' }}>
          <img src={Images.pic1} style={{ width: "80%" }} ></img>
        </Box>
        <DialogContent >
          <Typography sx={{ fontSize: '15px', color: '#484848', p: 1 }}>Forget Password</Typography>
          <Box id="alert-dialog-slide-description">
            <Box sx={{ '& > :not(style)': { m: 1 } }}>
              <form onSubmit={handleSubmit(submitForm)}>

                <Box sx={{ my: 2, py: 1, display: 'flex', alignItems: 'flex-end', bgcolor: 'white', borderRadius: '12px' }}>
                  <MailOutline sx={{ color: 'action.active', m: 1, my: 0.5 }} />
                  <TextField size="medium" fullWidth name="email" placeholder="Email" variant="standard"
                    {...register("email", {
                      required: "required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Entered value does not match email format"
                      }
                    })}
                    InputProps={{ disableUnderline: true }}
                    required />
                </Box>
                {errors.email && <span role="alert" style={{ color: 'red' }}>{errors.email.message}</span>}



                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button type="submit" variant="contained" sx={{ bgcolor: '#3B5998', px: 3, py: 1, borderRadius: 2, width: '75%' }} >Forget</Button>
                </Box>

              </form>

            </Box>
          </Box>


        </DialogContent>

      </Dialog>
    </Fragment>
  )
}

export default Forget