import { Box, Typography, Grid, CardMedia, Button, Rating } from '@mui/material'
import React from 'react'
import Layout from '../../layout/Layout'
import Images from '../../assets/Images/Images'
import { height } from '@mui/system'
import StarOutlineIcon from '@mui/icons-material/StarOutline';

function Aboutus() {

  return (

    <Layout>
      <CardMedia
        // component="img"
        sx={{
          width: "100%",
          borderRadius: 4,
          height: "100%",
          overflow: "hidden",
          my: 4,

        }}
        image={Images.aboutfirst}
        alt="green iguana"
      >
        <Box sx={{ bgcolor: 'rgba(0,0,0,0.5)', height: "100%", width: "100%", textAlign: "center" }}>
          <Typography variant='h3' sx={{
            py: 10,
            fontFamily: "barlow",
            fontWeight: 700,
            color: "#F3F8FE",
            mx: 6
          }}>
            About Us
          </Typography>
        </Box>
      </CardMedia>
      <Grid container spacing={6} sx={{ mb: 8, mt: 1 }} >
        <Grid item xs={4} md={4}>
          <Typography variant='h3' sx={{
            fontSize: { xl: 15, md: 40, xs: 30 },
            py: 6,
            fontFamily: "barlow",
            fontWeight: 700,
            color: "#212B36"
          }}>
            We Make The Best For All Our Customers.
          </Typography>
        </Grid>
        <Grid item xs={4} md={3.5}>
          <Typography variant='body1' sx={{
            fontSize: { xl: 10, md: 20, xs: 15 },
            py: 6,
            fontFamily: "poppins",

            fontWeight: 200,
            color: "#859EA4"
          }}>
            Curabitur ullamcorper ultricies nisi. Sed mollis, eros et ultrices tempus,
            mauris ipsum aliquam libero, non adipiscing dolor urna a orci.

          </Typography>
        </Grid>
        <Grid item xs={4} md={3.5}>
          <Typography variant='body1' sx={{
            fontSize: { xl: 10, md: 20, xs: 15 },
            py: 6,
            fontFamily: "poppins",
            fontWeight: 200,
            color: "#859EA4"
          }}>
            Donec vitae sapien ut libero venenatis faucibus. Vestibulum
            fringilla pede sit amet augue.

          </Typography>
        </Grid>
      </Grid>
      <Box >
        <CardMedia
          // component="img"
          sx={{
            width: "100%",
            borderRadius: 4,
            height: "100%",
            overflow: "hidden",
            my: 4,

          }}
          image={Images.about}
          alt="green iguana"
        >
          <Box sx={{ bgcolor: 'rgba(0,0,0,0.5)', height: "100%", width: "50%", textAlign: "center" }}>
            <Typography variant='h3' sx={{
              fontSize: { xl: 15, md: 55, xs: 30 },
              py: 6,
              fontFamily: "barlow",
              fontWeight: 700,
              color: "#F3F8FE",
              mx: 6
            }}>
              Our Agency Has Been
            </Typography>
            <Typography variant='body1' sx={{
              fontSize: { xl: 10, md: 18, xs: 15 },
              py: 2,
              fontFamily: "poppins",

              fontWeight: 500,
              color: "#C5C6C7",
              mx: 6
            }}>
              Hello. Our agency has been present for over 20 years.
              We make the best for all our customers.

            </Typography>
            <Grid container spacing={2} >
              <Grid item xs={6}>
                <Typography variant='h3' sx={{
                  fontSize: { xl: 15, md: 55, xs: 28 },
                  color: "#FA541C",
                  fontWeight: 700,
                  my: 3,

                }}>
                  2.23m+
                </Typography>
                <Typography variant='body1' sx={{
                  fontSize: { xl: 10, md: 18, xs: 15 },
                  color: "#C5C6C7",
                  fontWeight: 600,
                  my: 2,

                }}>
                  jobs
                </Typography>
              </Grid>
              <Grid item xs={6} >
                <Typography variant='h3' sx={{
                  fontSize: { xl: 15, md: 55, xs: 28 },
                  color: "#FA541C",
                  fontWeight: 700,
                  my: 3,

                }}>
                  500k+
                </Typography>
                <Typography variant='body1' sx={{
                  fontSize: { xl: 10, md: 18, xs: 15 },
                  color: "#C5C6C7",
                  fontWeight: 600,
                  my: 2,

                }}>
                  Successful Hiring
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='h3' sx={{
                  fontSize: { xl: 15, md: 55, xs: 28 },
                  color: "#FA541C",
                  fontWeight: 700,

                }}>
                  250+
                </Typography>
                <Typography variant='body1' sx={{
                  color: "#C5C6C7",
                  fontWeight: 600,
                  my: 2
                }}>
                  Partners
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='h3' sx={{
                  fontSize: { xl: 15, md: 55, xs: 28 },
                  color: "#FA541C",
                  fontWeight: 700,

                }}>
                  1.56k+
                </Typography>
                <Typography variant='body1' sx={{
                  color: "#C5C6C7",
                  fontWeight: 600,
                  my: 2
                }}>
                  Employee
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </CardMedia>

      </Box>

      <Grid container spacing={5} sx={{ my: 10 }}>
        <Grid item xs={12} md={4} >
          <Typography variant='h4' sx={{
            fontSize: { xl: 20, md: 35, xs: 33 },
            py: 3,
            fontFamily: "barlow",
            fontWeight: 650,
            color: "#212b36"
          }}>
            Fusce convallis metus id felis luctus
          </Typography>
          <Typography variant='body1' sx={{
            fontSize: { xl: 20, md: 18, xs: 18 },
            py: 2,
            fontFamily: "poppins",

            fontWeight: 200,
            color: "#859EA4",
          }}>
            Curabitur ullamcorper ultricies nisi. Sed mollis, eros et ultrices tempus,
            mauris ipsum aliquam libero, non adipiscing dolor urna a orci.

          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <CardMedia
            component="img"
            height="100%"
            sx={{
              width: "100%",
              borderRadius: 7,


            }}
            image={Images.aboutstyle}
            alt="...."
          >

          </CardMedia>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant='h4' sx={{
            fontSize: { xl: 20, md: 35, xs: 33 },
            py: 3,
            fontFamily: "barlow",
            fontWeight: 650,
            color: "#212b36",

          }}>
            Fusce convallis metus id felis luctus
          </Typography>
          <Typography variant='body1' sx={{
            fontSize: { xl: 20, md: 18, xs: 18 },
            py: 2,
            fontFamily: "poppins",

            fontWeight: 200,
            color: "#859EA4",

          }}>
            Curabitur ullamcorper ultricies nisi. Sed mollis, eros et ultrices tempus,
            mauris ipsum aliquam libero, non adipiscing dolor urna a orci.

          </Typography>
        </Grid>
      </Grid>

      <Box sx={{ width: 300, mx: "auto", textAlign: "center", }}>
        <Typography variant='h3' sx={{
          py: 4,
          fontFamily: "barlow",
          fontWeight: 700,
          color: "#212B36"
        }}>
          What Our Customer Say
        </Typography>
        <Rating
          precision={4}
          emptyIcon={<StarOutlineIcon style={{ opacity: 7 }} fontSize="inherit" />}
        />
        <Typography variant='body1' sx={{
          fontFamily: "poppins",
          color: "#212b36",
          fontWeight: 400,
          fontSize: "18px",
          py: 4
        }}>
          Amazing experience. I Love it alot. Thanks to the Tean that dreams come True, Great! I Appreciate their Attitude and Approach.
        </Typography>
      </Box>

      <Box sx={{
        bgcolor: "#303030",
        textAlign: "center",
        borderRadius: 4
      }}>
        <Typography variant='h4' sx={{
          color: "#fff",
          p: 4,
        }}>
          Want to find out how we can work together?
        </Typography>
        <Button variant="contained" sx={{
          bgcolor: "#3B5998",
          p: 2,
          fontSize: "17px",
          mb: 3
        }}>
          Contact Us Today
        </Button>
      </Box>


    </Layout>

  )

}
export default Aboutus