import { Box, Button, FormControl, TextField } from '@mui/material'
import React, { useRef, useState } from 'react'
import Images from '../../assets/Images/Images'
import Layout from '../../layout/Layout';
import { useForm } from 'react-hook-form';
import { Service } from './../../config/service';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ErrorToaster, SuccessToaster } from '../../components/UI/Toaster';

function ForgetPassword() {

  const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
  const password = useRef({});
  const navigate = useNavigate()
  password.current = watch("password", "");
  const [param] = useSearchParams()
  const token = param.get('token')


  const submitForm = async (data) => {
    try {
      const { response, status, message } = await Service.resetPassword({ password: data.password, token: token })
      if (status === true && response === 200) {
        reset()
        SuccessToaster("Password Updated")
        navigate("/")
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  return (
    <Layout>
      <Box sx={{ position: "relative", height: "80vh" }}>

        <Box sx={{ borderRadius: 3, bgcolor: " white", boxShadow: 4, position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)", width: 300, padding: 6 }}>
          <Box sx={{ my: '-14px', display: "flex", justifyContent: "center" }}>

            <img src={Images.pic1}  ></img>
          </Box>
          <form onSubmit={handleSubmit(submitForm)}>
            <FormControl sx={{ marginY: 3, width: "100%" }}>

              <TextField label="New-Password" type="password" variant="outlined"
                {...register('password', {
                  required: "required",
                  minLength: {
                    value: 7,
                    message: "min length is 7"
                  }

                })}
              />
              {errors.password && <span role="alert" style={{ color: 'red' }}>{errors.password.message}</span>}
            </FormControl>
            <FormControl sx={{ marginY: "3", width: "100%" }}>
              <TextField label="Confirm-Password" variant="outlined" type="password"
                {...register('confirmpassword', {
                  required: "required",
                  validate: value =>
                    value === password.current || "The passwords do not match"

                })}
              />
            </FormControl>
            {errors.confirmpassword && <span role="alert" style={{ color: 'red' }}>{errors.confirmpassword.message}</span>}
            <Button type='submit' variant="contained" sx={{ borderRadius: 1.5, p: 1.5, width: "100%", marginTop: 4, bgcolor: "#3B5998" }}>Change Password</Button>
          </form>
        </Box>


      </Box>

    </Layout>
  )

}
export default ForgetPassword