import React, { Fragment, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { LoadingButton } from '@mui/lab';

import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';

import HttpsIcon from '@mui/icons-material/Https';
import PhoneIcon from '@mui/icons-material/Phone';
import CloseIcon from '@mui/icons-material/Close';
import { FormControl, IconButton, Input, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material';
import { useForm } from "react-hook-form";
import { Service } from '../../config/service';
import Images from '../../assets/Images/Images';
import SignIn from './../SignIn/SignIn';
import { MailOutline, Visibility, VisibilityOff } from '@mui/icons-material';
import { ErrorToaster, SuccessToaster } from '../UI/Toaster';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Signup = ({ openDialog, onClose, openOtpDialog, registerData }) => {

  const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
  const password = useRef({});
  password.current = watch("password", "");

  const [loading, setLoading] = useState(false);
  const [values2, setValues2] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword2: false,
  });
  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleClickShowConfirmPassword = () => {
    setValues2({
      ...values2,
      showPassword2: !values2.showPassword2,
    });
  };


  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const submitForm = async (data) => {
    setLoading(true)
    try {
      registerData(data)
      let obj = {
        phoneno: data.phoneno,
        email: data.email
      }
      const { response, status } = await Service.signUp(obj)
      if (response === 200 && status === true) {
        reset()
        onClose()
        openOtpDialog();
      }
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  };

  return (
    <Fragment>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"

        PaperProps={{
          style: {
            backgroundColor: "#F9F9F9",
            boxShadow: "0px 1px 5px #00000012",
            borderRadius: '14px',
            width: '380px'
          },
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 1, mx: '17px' }}>
          <CloseIcon onClick={onClose} sx={{ color: '#898B8F', fontSize: '28px', cursor: 'pointer' }} />
        </Box>
        <Box sx={{ display: 'block', mx: 'auto', my: '-18px' }}>
          <img src={Images.pic1} alt="logo"   ></img>
        </Box>
        <DialogContent >
          <Typography sx={{ fontSize: '15px', color: '#484848', p: 1 }}>Sign to your Account</Typography>
          <Box id="alert-dialog-slide-description">
            <Box sx={{ '& > :not(style)': { m: 1 } }}>
              <form onSubmit={handleSubmit(submitForm)}>
                <Box sx={{ my: 2, py: 1, display: 'flex', alignItems: 'flex-end', bgcolor: 'white', borderRadius: '12px' }}>
                  <PersonOutlineOutlinedIcon sx={{ color: 'action.active', m: 1, my: 0.5 }} />
                  <TextField size="medium" fullWidth name="name" placeholder="Enter Full Name" variant="standard"
                    {...register("name", {
                      required: "Name is Required"
                    })}
                    InputProps={{ disableUnderline: true }}
                    required />
                </Box>

                <Box sx={{ my: 2, py: 1, display: 'flex', alignItems: 'flex-end', bgcolor: 'white', borderRadius: '12px' }}>
                  <PhoneIcon sx={{ color: 'action.active', m: 1, my: 0.5 }} />
                  <TextField type='number' size="medium" fullWidth name="phoneno" placeholder="Phone Number" variant="standard"
                    {...register("phoneno", {
                      required: "Phone Number is Required",
                      pattern: {
                        value: /^((\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$/,
                        message: "Entered Value Does not Match Phone Number Format"
                      }
                    })}
                    InputProps={{ disableUnderline: true }}
                    required />
                </Box>
                {errors.phoneno && <span role="alert" style={{ color: 'red' }}>{errors.phoneno.message}</span>}
                <Box sx={{ my: 2, py: 1, display: 'flex', alignItems: 'flex-end', bgcolor: 'white', borderRadius: '12px' }}>
                  <MailOutline sx={{ color: 'action.active', m: 1, my: 0.5 }} />
                  <TextField size="medium" fullWidth name="email" placeholder="Email" variant="standard"
                    {...register("email", {
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Entered Value Does not Match Email Format"
                      }
                    })}
                    InputProps={{ disableUnderline: true }}
                    required />
                </Box>
                {errors.email && <span role="alert" style={{ color: 'red' }}>{errors.email.message}</span>}
                <Box sx={{ mt: 1, py: 0.1, display: 'flex', alignItems: 'flex-end', bgcolor: 'white', borderRadius: '12px' }}>
                  <HttpsIcon sx={{ color: 'action.active', mb: 1.5 }} />
                  <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                    {/* <InputLabel htmlFor="outlined-adornment-password"></InputLabel> */}
                    <Input sx={{ outline: 'none', }}
                      placeholder='password'
                      disableUnderline
                      notched={true}
                      id="adornment-password"
                      type={values.showPassword ? 'text' : 'password'}
                      {...register("password", {
                        required: "Password is required",
                        minLength: {
                          value: 6,
                          message: "Password Minimum Length is 6"
                        }
                      })}

                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }

                    />
                  </FormControl>
                </Box>
                {/* <Box sx={{ my: 2, py: 1, display: 'flex', alignItems: 'flex-end', bgcolor: 'white', borderRadius: '12px' }}>
                  <HttpsIcon sx={{ color: 'action.active', m: 1, my: 0.5 }} />
                  <FormControl fullWidth>
                    <TextField fullWidth name="password" placeholder="Password" variant="standard" InputProps={{ disableUnderline: true }}
                      type="password"
                      {...register("password", {
                        required: "Password is required",
                        minLength: {
                          value: 6,
                          message: "Password Minimum Length is 6"
                        }
                      })}

                    />

                  </FormControl>
                </Box> */}


                {errors.password && <span style={{ color: 'red' }} role="alert">{errors.password.message}</span>}
                {/* <Box sx={{ my: 2, py: 1, display: 'flex', alignItems: 'flex-end', bgcolor: 'white', borderRadius: '12px' }}>
                  <HttpsIcon sx={{ color: 'action.active', m: 1, my: 0.5 }} />
                  <FormControl fullWidth>
                    <TextField name="confirmPassword" placeholder="Confirm Password" variant="standard" InputProps={{ disableUnderline: true }}
                      type="password"
                      {...register('confirmPassword', {
                        required: "Confirm Password Is Required",
                        validate: value =>
                          value === password.current || "The Passwords Do not Match"
                      })}
                      required
                      fullWidth
                    />

                  </FormControl>

                </Box> */}
                <Box sx={{ my: 1, py: 0.1, display: 'flex', alignItems: 'flex-end', bgcolor: 'white', borderRadius: '12px' }}>
                  <HttpsIcon sx={{ color: 'action.active', mb: 1.5 }} />
                  <FormControl sx={{ m: 1, width: '100%' }} variant="outlined" >
                    {/* <InputLabel htmlFor="outlined-adornment-password"> Confirm Password</InputLabel> */}
                    <Input
                      // inputProps={{ disableUnderline: true }}
                      disableUnderline
                      placeholder='confirm password'
                      id="adornment-password"
                      type={values2.showPassword2 ? 'text' : 'password'}

                      {...register('confirmPassword', {
                        required: "Confirm Password Is Required",
                        validate: value =>
                          value === password.current || "The Passwords Do not Match"
                      })}
                      sx={{ width: "100%" }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values2.showPassword2 ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }

                    />
                  </FormControl>
                </Box>
                {errors.confirmPassword && <span role="alert" style={{ color: 'red' }}>{errors.confirmPassword.message}</span>}
                <LoadingButton loading={loading} type="submit" variant="contained" sx={{ bgcolor: '#3B5998', px: 3, py: 1, borderRadius: 2, width: '100%' }}  >Sign up</LoadingButton>

              </form>

            </Box>
          </Box>
          {/* <Typography sx={{ fontSize: '14px', textAlign: 'center', mt: 2 }}>Sign Up With</Typography> */}
        </DialogContent>

        {/* <Box sx={{ display: 'flex', justifyContent: 'space-around', mb: 3, p: 1 }}>
          <Button sx={{ px: 5, py: 2, bgcolor: 'white', borderRadius: "12px" }} ><img src={Images.google}></img></Button>
          <Button sx={{ px: 5, py: 2, bgcolor: 'white', borderRadius: "12px" }}><img src={Images.facebook}></img></Button>
          <Button sx={{ px: 5, py: 2, bgcolor: 'white', borderRadius: "12px", boxShadow: '0px 2px 6px #3B599826' }}><img src={Images.email}></img></Button>
        </Box> */}
      </Dialog >

    </Fragment >
  )
}


export default Signup