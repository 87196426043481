import { Navigate } from 'react-router-dom';

// *Import Components

import Form from './../views/Form/Form';
import Sell from './../views/Sell/Sell';
import Home from './../views/Home/Home';
import MyAds from '../views/MyAds/MyAds';
import SubCategory from '../views/SubCatgeory/SubCatgeory';
import AdDetails from '../views/AdsDetails/AdDetails';
import ForgetPassword from '../views/ForgetPassword/ForgetPassword';
import Profile from '../views/Profile/Profile';
import EditProfile from './../views/EditProfile/EditProfile';
import Favourite from '../views/Favourite/Favourite';
import SearchAds from '../views/SearchAds/SearchAds';
import EditForm from './../views/EditForm/EditForm';
import Aboutus from '../views/Aboutus/Aboutus';
import Contactus from '../views/Contactus/Contactus';
import PrivacyPolicy from '../views/PrivacyPollicy/PrivacyPolicy';
import TermsOfUse from '../views/TermsOfUse/TermsOfUse';
import Propertyform from '../views/Form/Propertyform';

export const AppRoutes = [
    {
        path: "/",
        component: <Home />
    },
    {
        path: '/ads/:id',
        component: <AdDetails />
    },
    {
        path: '/profile/:id',
        component: <Profile />
    },
    {
        path: '/item',
        component: <SearchAds />
    },
    {
        path: '/forgetpassword',
        component: <ForgetPassword />
    },
    {
        path: "*",
        component: <Navigate to="/404" />
    },
    {
        path: '/about-us',
        component: <Aboutus />
    },
    {
        path: '/contact-us',
        component: <Contactus />
    },
    {
        path: '/privacy-policy',
        component: <PrivacyPolicy />
    },
    {
        path: '/terms-of-use',
        component: <TermsOfUse />
    },
    {
        path: "/property-form",
        component: <Propertyform />
    },

]

export const PrivateRoutes = [
    {
        path: '/sell',
        component: <Sell />
    },
    {
        path: "/myads",
        component: <MyAds />
    },
    {
        path: '/sell/:name',
        component: <SubCategory />
    },
    {
        path: "/form/:name",
        component: <Form />
    },

    {
        path: "/edit/form/",
        component: <EditForm />
    },
    {
        path: "/edit/profile",
        component: <EditProfile />
    },
    {
        path: '/favourite',
        component: <Favourite />
    },
    {
        path: '/edit-ads/:id',
        component: <EditForm />
    },

]
