import React, { useEffect } from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom'

import Home from './views/Home/Home';

import Sell from './views/Sell/Sell';
import MyAds from './views/MyAds/MyAds';
import Header from './layout/Header';
import Form from './views/Form/Form';
import ForgetPassword from './views/ForgetPassword/ForgetPassword';
import SubCategory from './views/SubCatgeory/SubCatgeory';
import AdDetails from './views/AdsDetails/AdDetails';
import useAuth from './hooks/useAuth';
import { AppRoutes, PrivateRoutes } from './routes/routes';

function App() {

  const { user, verifyToken } = useAuth()
  const token = localStorage.getItem('token') || ''

  useEffect(() => {
    verifyToken()
  }, []);


  return (

    <BrowserRouter>
      <Routes>
        {AppRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={route.component}
          />
        ))}
        <Route element={token ? <Outlet /> : <Navigate to="/" />}>
          {PrivateRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={route.component}
            />
          ))}
        </Route>



        {/* 
       
        <Route path='/forgetpassword' element={<ForgetPassword />} /> */}

      </Routes>


    </BrowserRouter>

  )
}
export default App