import { Box, Button, CardMedia, Container, Divider, Grid, Typography, MenuItem, Menu, OutlinedInput, FormControl, Card, CardContent, CardActions } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import Images from '../../assets/Images/Images';
import { CircularProgress } from '@mui/material';
import { FavoriteBorderOutlined, Search } from '@mui/icons-material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import moment from 'moment'
import { Link } from 'react-router-dom';
import { Service } from '../../config/service';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SignIn from '../SignIn/SignIn';

function SimpleCard({ data }) {

  const token = localStorage.getItem('token') || ''
  const [favAdd, setFavAdd] = useState([]);
  const [openSignInDialog, setOpenSignInDialog] = useState(false);

  const addFav = async (id) => {
    try {
      if (!token) {
        setOpenSignInDialog(true);
        return
      }
      let obj = {
        ad_Id: id
      }
      const { response } = await Service.addFav(obj, token)
      if (response === 200) {
        setFavAdd(prevFavAdd => {
          const index = prevFavAdd.findIndex(element => element === id);
          if (index === -1) {
            return [...prevFavAdd, id];
          } else {
            return prevFavAdd.filter(element => element !== id);
          }
        });
      }
    } catch (error) {
      console.log('file: SImpleCard.js => line 41 => addFav => error', error)
    }
  }

  const defaultFav = () => {
    try {
      let array = []
      data.forEach(element => {
        if (element.favourite === true) {
          array.push(element._id)
        }
      });
      setFavAdd(array)
    } catch (error) {

    }
  }

  useEffect(() => {
    if (data) {
      defaultFav()
    }
  }, [data]);

  return (
    <>
      <SignIn openDialog={openSignInDialog} onClose={() => setOpenSignInDialog(false)} />
      <Grid container spacing={2}  >
        {data.length > 0 ?
          data.map((item, index) => (

            <Grid key={index} item md={2.4} xs={12} sm={12}>

              <Card sx={{ width: { md: "220px !important", xs: "320px !important" }, height: "100%" }}>
                <Link to={`/ads/${item.ad_id}`}>
                  <CardMedia
                    component="img"

                    sx={{ height: { md: "210px", xs: "200px" }, }}
                    image={item.images[0]}
                  />
                </Link>
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>

                    <Typography noWrap variant="body2" component="div" sx={{ color: "#484848", fontSize: "14px" }}>
                      {item.title}
                    </Typography>

                    {favAdd.findIndex(e => e === item.ad_id) !== -1 ?
  <FavoriteIcon sx={{ color: '#2c457a', cursor: 'pointer' }} onClick={() => addFav(item.ad_id)} />
  : <FavoriteBorderOutlined sx={{ cursor: 'pointer', ':hover': { color: '#2c457a' } }} onClick={() => addFav(item.ad_id)} />
}
                  </Box>
                  <Typography variant="body2" sx={{ color: "#484848", fontWeight: 700, fontSize: "16px" }}>
                    Rs. {item.price}
                  </Typography>
                  <Box sx={{ pt: { md: 1, xs: 1 } }}>
                    <Box>
                      <LocationOnOutlinedIcon sx={{ fontSize: { md: "15px", xs: "18px" }, verticalAlign: 'bottom' }} />
                      <Typography component={"span"} variant="body2" sx={{ color: "#898B8F", ml: 0.5, fontWeight: 700, fontSize: { md: "9px", xs: "11px" }, }}>
                        {item.subdivision_name}, {item.city_name}
                      </Typography>
                    </Box>
                    <Box>
                      <EventNoteOutlinedIcon sx={{ fontSize: { md: "15px", xs: "18px" }, verticalAlign: 'bottom' }} />
                      <Typography component={"span"} variant="body2" sx={{ color: "#898B8F", ml: 0.5, fontWeight: 700, fontSize: { md: "9px", xs: "11px" }, textAlign: "right", }}>
                        {moment(item.posted_On).format('DD MMM YYYY')}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>

              </Card>

            </Grid>

          )) :
          <Box sx={{ margin: 'auto' }}>
            <CircularProgress />
          </Box>
        }
      </Grid >
    </>

  )
}
export default SimpleCard
