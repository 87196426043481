import { Box, CardMedia, Grid, Typography, CardContent, Item, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Layout from '../../layout/Layout';
import Images from '../../assets/Images/Images';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Service } from '../../config/service';
import CircularProgress from '@mui/material/CircularProgress';

function SubCategory() {

  const { state } = useLocation()
  const [subCategory, setSubCategory] = useState()
  async function fetchData() {
    const { data } = await Service.getSubCategory(state._id)
    setSubCategory(data.categories)
  }
  useEffect(() => {

    fetchData()

  }, [])

  return (
    <Layout>
      <Box sx={{ bgcolor: "#F7F8F8", p: { md: 6, xs: "none" } }}>
        <Box sx={{ width: "100%", bgcolor: "#f5f5f5" }}>
          <Box >
            <Typography sx={{ color: "#484848", fontSize: "25px", fontWeight: 600, p: 3 }}>
              {state.name}
            </Typography>
          </Box>
          <Box sx={{ width: "100%", height: "195px", bgcolor: "#3B5998", borderRadius: " 0px 0px 50px 50px", my: 2 }}>
            <Box sx={{ pt: 4, display: "flex", justifyContent: "center", color: "#3B5998" }}>
              <CardMedia
                component="img"
                height="120"
                image={Images.pngpic}
                alt="load"
                sx={{ width: "290px !important" }}
              />
            </Box>
          </Box>
          <Box p={10} paddingRight={3}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              {
                subCategory ?
                  subCategory.map((item) => {
                    return (
                      <Grid item xs={12} md={6} key={item._id}>
                        <Link to={`/form/${item.name}`} state={item}>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '282px', height: '60px', bgcolor: "#FFFFFF", boxShadow: "0px 2px 16px #7D7D7D14", borderRadius: 2 }} >
                            <Box sx={{ display: 'flex' }} >
                              <CardMedia
                                component="img"
                                height="20"
                                image={item.image}
                                alt="load"
                                sx={{ width: "20px !important", objectFit: "contain !important", marginTop: 2.5, marginLeft: 2 }}
                              />

                              <Typography sx={{ marginLeft: 3, marginTop: 2.5, color: "#484848" }}>
                                {item.name}
                              </Typography>
                            </Box>

                            <ArrowForwardIosIcon sx={{ mr: 2, mt: 3, fontSize: '16px', color: '#E2E2E2' }} />

                          </Box>
                        </Link>
                      </Grid>
                    )
                  }) : <Box sx={{ margin: 'auto' }} >
                    <CircularProgress />
                  </Box>
              }

            </Grid>
          </Box>
        </Box>


      </Box>
    </Layout >
  )
}

export default SubCategory