import Axios from '../../axios';
import { errorHandler } from './errorHandler';

export const Apis = {
    signUp: 'api/signUp',
    verifySignup: 'api/verifySignup',
    resendOTP: 'api/resendOtp',
    signIn: 'api/signIn',
    forgetPassword: 'api/forgetpassword',
    resetPassword: 'api/resetpassword',
    getCategory: 'api/getParent',
    getSubCategory: 'api/get_subCats',
    insertAds: 'api/addadvertise',
    recentAds: 'api/recent_ads',
    insertImage: 'api/insertimage',
    user: 'api/specificuser',
    allCities: 'api/Allcities',
    getSub_Division: 'api/getsub_Division',
    myAds: 'api/myads',
    userAds: 'api/User_Advertise',
    adsDetail: 'api/specificadvertise',
    specificProfile: 'api/specProfile',
    userProfileUpdate: 'api/profileUpdate',
    addFav: 'api/add_fav',
    user_fav_Ads: 'api/user_fav_Ads',
    change_password: 'api/change_password',
    searchAds: 'api/search_api',
    getLocation: 'api/google_map',
    contactForm: 'api/inquiry',
    sideFilter: 'api/sidefilter',
    autoCompleteDropDown: 'api/autocomplete',
    changeAdStatus: 'api/change_status',
    updateAds: '/api/updateadvertise/'
};

export const headers = {
    'content-type': 'application/json',
};

export const get = async (endPoint, token) => {
    try {
        const result = await Axios.get(endPoint, { headers: { 'x-access-token': token } });
        return result;
    } catch (e) {
        throw errorHandler(e);
    }
};

export const post = async (endPoint, data, token) => {
    try {
        const result = await Axios.post(endPoint, data, { headers: { 'x-access-token': token } });
        return result;
    } catch (e) {
        throw errorHandler(e);
    }
};

export const put = async (endPoint, data, token) => {
    try {
        const result = await Axios.put(endPoint, data, { headers: { 'x-access-token': token } });
        return result;
    } catch (e) {
        throw errorHandler(e);
    }
};