import { Box, Typography, Grid, CardMedia, Button, Rating, Divider } from '@mui/material'
import React from 'react'
import Layout from '../../layout/Layout'
import Images from '../../assets/Images/Images'
function PrivacyPolicy() {
  return (
    <Layout>
      <Box sx={{
        p: { md: 6, xs: "none" },
        mx: { md: 6, xs: "none" },
      }}>
        <ol>
          <Typography variant='h3' sx={{
            py: 3,
            fontFamily: "barlow",
            fontWeight: 650,
            color: "#212b36",
            textAlign: "center"
          }}>
            What are Privacy Policies?
          </Typography>
          <Typography variant='body1' sx={{
            py: 2,
            textAlign: "center",
            fontSize: "16px",
            fontWeight: 400,
            color: "#493941"
          }}>
            Curabitur ullamcorper ultricies nisi. Sed mollis, eros et ultrices tempus,
            mauris ipsum aliquam libero, non adipiscing dolor urna a orci.

          </Typography>
          <Typography variant='h6' sx={{
            py: 3,
            fontFamily: "barlow",
            fontWeight: 650,
            color: "#212b36"
          }}>
            Contents
          </Typography>
          <ol>
            <li>
              <a href="#"> Who are we? </a>
            </li>
            <li>
              <a href="#"> What data do we collect about you? </a>
            </li>
            <li>
              <a href="#"> Do we collect data from children? </a>
            </li>
            <li>
              <a href="#"> Why do we process your personal information? </a>
            </li>
            <li>
              <a href="#"> How will we inform you about changes in our privacy statement? </a>
            </li>
            <li>
              <a href="#">Communication and marketing  </a>
            </li>
            <li>
              <a href="#">  Who do we share your data with?</a>
            </li>
          </ol>
          <Typography variant='body1' sx={{

            my: 4,
            fontSize: "16px",
            fontWeight: 400,
            color: "#493941"
          }}>
            In this privacy statement:

          </Typography>
          <Typography variant='body1' sx={{

            my: 4,
            fontSize: "16px",
            fontWeight: 400,
            color: "#493941"
          }}>
            <strong>Services</strong>  means any products, services, content, features, technologies, or functions, and all related websites,
            applications and services offered to you by us.

          </Typography>
          <Typography variant='body1' sx={{

            my: 1,
            fontSize: "16px",
            fontWeight: 400,
            color: "#493941"
          }}>
            Platform means the websites, mobile apps, mobile sites or other online properties
            through which we offer our Services.

          </Typography>
          <Typography variant='h6' sx={{
            m: 3,
            fontFamily: "barlow",
            fontWeight: 650,
            color: "#212b36"
          }}>

            <li>Who are we?</li>

          </Typography>
          <Typography variant='body1' sx={{

            my: 2,
            fontSize: "16px",
            fontWeight: 400,
            color: "#493941"
          }}>
            The principal controller of your data for purposes of our
            international services is Apas Ki DealGlobal B.V., a limited liability company
            with its office at 105 Taurusavenue, 2132 LS Hoofddorp, the Netherlands.
            Apas Ki DealGlobal B.V is the parent company of the Apas Ki Dealgroup entities that are
            providing the services to you according to our Terms of Use, (hereinafter
            collectively referred to as <strong>“OLX”</strong>, <strong>“we”</strong>,
            <strong>“us”</strong>  or <strong>“our”</strong>  in this privacy
            statement). These Apas Ki Dealgroup entities are also considered data
            controllers for the local services they offer.
            Relevant contact details are set out in section 13.

          </Typography>
          <Typography variant='h6' sx={{
            m: 3,
            fontFamily: "barlow",
            fontWeight: 650,
            color: "#212b36"
          }}>

            <li>What data do we collect about you?</li>

          </Typography>
          <Typography variant='h6' sx={{
            m: 3,
            fontFamily: "barlow",
            fontWeight: 650,
            color: "#212b36"
          }}>
            <li>
              <u> <i>Data provided through direct interactions</i></u>
            </li>
          </Typography>
          <Typography variant='h6' sx={{
            py: 2,
            fontFamily: "barlow",
            fontWeight: 650,
            color: "#212b36"
          }}>
            Registration and other account information
          </Typography>
          <Typography variant='body1' sx={{
            py: 2,
            fontSize: "16px",
            fontWeight: 400,
            color: "#493941"
          }}>
            When you register to use our Services we may
            collect the following information about you:
          </Typography>
          <Typography variant='body1' sx={{
            m: 3,
            fontSize: "16px",
            fontWeight: 400,
            color: "#493941"
          }}>
            <ol>
              <li>
                if you register using your Google account: first name, last name and email address;
              </li>
              <li>
                if you register using your Facebook account: we collect first name and last name as appeared on your Facebook account and Facebook IDs. In the event you have provided permission to Facebook through their in-app privacy option (which appears just before you register on our Platform), we may collect your gender,
                age or email id depending on the permissions granted by you; and
              </li>
              <li>
                if you register using your mobile number: mobile number.
              </li>
            </ol>
          </Typography>

          <Typography variant='body1' sx={{
            m: 3,
            fontSize: "16px",
            fontWeight: 400,
            color: "#493941"
          }}>
            Depending on the choices you make during the log-in to our Services or during the process of engaging our Services, you may opt to give
            the following additional personal data:
          </Typography>
          <Typography variant='body1' sx={{
            m: 3,
            fontSize: "16px",
            fontWeight: 400,
            color: "#493941"
          }}>
            <ol>
              <li>
                your name;
              </li>
              <li>
                e-mail address;
              </li>
              <li>
                your credit card details in case you want to purchase
                our paid services as defined in our Terms of Use.
              </li>
            </ol>
          </Typography>

          <Typography variant='h6' sx={{
            py: 2,
            fontFamily: "barlow",
            fontWeight: 650,
            color: "#212b36"
          }}>
            Communication through the chat feature on our Platform
          </Typography>
          <Typography variant='body1' sx={{
            my: 4,
            fontSize: "16px",
            fontWeight: 400,
            color: "#493941"
          }}>
            When you use our chat feature to communicate with other users, we collect information that you choose
            to provide to other users through this feature.
          </Typography>
          <Typography variant='h6' sx={{
            m: 3,
            fontFamily: "barlow",
            fontWeight: 650,
            color: "#212b36"
          }}>
            <li>
              <u> <i>Data we collect automatically when you use our Services</i></u>
            </li>
          </Typography>
          <Typography variant='body1' sx={{
            my: 4,
            fontSize: "16px",
            fontWeight: 400,
            color: "#493941"
          }}>
            When you interact with our Platform or use our Services,
            we automatically collect the following information about you:
          </Typography>
          <Typography variant='h6' sx={{
            py: 2,
            fontFamily: "barlow",
            fontWeight: 650,
            color: "#212b36"
          }}>
            Device Information
          </Typography>
          <Typography variant='body1' sx={{
            m: 3,
            fontSize: "16px",
            fontWeight: 400,
            color: "#493941"
          }}>

            We collect device-specific information such as operating system version and unique identifiers. For example, the name of the mobile network that you are using.
            We associate the device identifiers with your Apas Ki Dealaccount.
          </Typography>
          <Typography variant='h6' sx={{
            py: 2,
            fontFamily: "barlow",
            fontWeight: 650,
            color: "#212b36"
          }}>
            Client and Log data
          </Typography>
          <Typography variant='body1' sx={{
            m: 3,
            fontSize: "16px",
            fontWeight: 400,
            color: "#493941"
          }}>

            Technical details, including the Internet Protocol (IP) address of your device, time zone and operating system. We will also store your login information (registration date, date of last password change,
            date of last successful login), type and version of your browser.
          </Typography>
          <Typography variant='h6' sx={{
            py: 2,
            fontFamily: "barlow",
            fontWeight: 650,
            color: "#212b36"
          }}>
            Cookies and Similar Technologies
          </Typography>
          <Typography variant='body1' sx={{
            p: 3,
            fontSize: "16px",
            fontWeight: 400,
            color: "#493941"
          }}>

            We use cookies to manage our users’ sessions, to store your preferences language selection and deliver you relevant advertisements. "Cookies" are small text files transferred by a web server to the hard drive of your device. Cookies may be used to collect the date and time of your visit, your browsing history, your preferences, and your username. You can set your browser to refuse all or some cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of our Services/Platform may become inaccessible or not function properly. For more information on the cookies we use, please see our Policy on Cookies and Similar Technologies, the terms of which are incorporated into, and form part of,
            this Privacy Policy by reference.
          </Typography>
        </ol>
      </Box>
    </Layout>
  )
}
export default PrivacyPolicy