import { ExpandLess, ExpandMore, Favorite, FavoriteBorderOutlined, FilterAlt, Search, StarBorder } from '@mui/icons-material';
import { Typography, Grid, Divider, ListItemButton, ListItemIcon, ListItemText, Collapse, List, Box, FormControl, InputLabel, Select, MenuItem, Slider, Card, CardMedia, CardContent, InputAdornment, TextField, Autocomplete, Breadcrumbs, Input, CircularProgress, Chip, SwipeableDrawer, Button, ToggleButton } from '@mui/material';
import React, { useState, useEffect, Fragment } from 'react'
import Layout from './../../layout/Layout';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import { Service } from './../../config/service';
import { Link, useParams, useLocation, useSearchParams, Route, useNavigate } from 'react-router-dom';
import moment from 'moment';
import useAuth from '../../hooks/useAuth';
import SignIn from '../../components/SignIn/SignIn';
import { LoadingButton } from '@mui/lab';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';


const SearchAds = () => {
  const handleChange = (event, value) => {
    try {
      let searchValue
      if (value) {
        searchValue = value
      } else {
        searchValue = event.target.value
      }
      setSearch(searchValue)
    }
    catch (error) {
      console.log("🚀 ~ file: SearchAds.js ~ line 27 ~ handleChange ~ error", error)

    }
  }

  const handleClick = async () => {
    navigate(`/item?search=${search}`)

  }
  useEffect(() => {

    getAutoCompleteDropDown()
  }, [])


  const { user } = useAuth();
  const navigate = useNavigate();
  const token = localStorage.getItem('token') || ''

  const [searchParams, setSearchParams] = useSearchParams()

  const [search, setSearch] = useState(searchParams.get('search'));
  const [cat, setCat] = useState({ categoryId: searchParams.get('categoryId'), categoryName: searchParams.get('categoryName') });
  const [city, setCity] = useState({ cityId: searchParams.get('cityId'), cityName: searchParams.get('cityName') });
  const [area, setArea] = useState({ areaId: searchParams.get('areaId'), areaName: searchParams.get('areaName') });
  const [minPrice, setMinPrice] = useState(searchParams.get('minPrice'));
  const [maxPrice, setMaxPrice] = useState(searchParams.get('maxPrice'));
  const [autoCompleteDropDown, setAutoCompleteDropDown] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);

  // *For Get Ads
  const [ads, setAds] = useState([]);

  // *For Location
  const [location, setLocation] = useState([]);

  // *For Category
  const [category, setCategory] = useState([]);

  // *For Add Favorite
  const [addFav, setAddFav] = useState([]);

  // *For Open SignIn Dialog
  const [openSignInDialog, setOpenSignInDialog] = useState(false);

  const [totalAds, setTotalAds] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const [inputMinValue, setInputMinValue] = useState(1);
  const [inputMaxValue, setInputMaxValue] = useState(100000);

  // *For Selected Filter
  const [selectedFiltered, setSelectedFiltered] = useState([]);

  const [expand, setExpand] = useState([]);

  // *For Get Ads By Filter
  const getAdsByFilter = async (p) => {
    setLoading(true)
    setLoader(true)
    try {
      const title = search ? search : ''
      const cityId = city.cityId ? city.cityId : ''
      const lPrice = maxPrice ? maxPrice : ''
      const gPrice = minPrice ? minPrice : ''
      const category = cat.categoryId ? cat.categoryId : ''
      const subdivision = area.areaId ? area.areaId : ''
      const page = p !== undefined ? p + pageNumber : pageNumber
      setPageNumber(page)
      const { data, Total_ADS } = await Service.searchAds(title, cityId, gPrice, lPrice, category, subdivision, page, token)
      setAds(data)
      defaultFav(data)
      setTotalAds(Total_ADS)
    } catch (error) {
      console.log('file: SearchAds.js => line 27 => getAdsByFilter => error', error)
    } finally {
      setLoader(false)
      setLoading(false)
    }
  }

  // *For Get Side Filter
  const getSideFilter = async () => {
    try {
      const { city, categories } = await Service.sideFilter()
      setCategory(categories)
      setLocation(city)
    } catch (error) {
      console.log('file: SearchAds.js => line 27 => getSideFilter => error', error)
    }
  }

  // *For Add To Favorite
  const addToFav = async (id) => {
    try {
      if (!token) {
        setOpenSignInDialog(true);
        return
      }
      let obj = {
        ad_Id: id
      }
      const { response } = await Service.addFav(obj, token)
      if (response === 200) {
        let array = [...addFav]
        const index = array.findIndex(element => element === id)
        if (index === -1) {
          array.push(id)
        } else {
          array.splice(index, 1)
        }
        setAddFav(array)
      }
    } catch (error) {
      console.log('file: SImpleCard.js => line 41 => addFav => error', error)
    }
  }

  // *For Default Favorite
  const defaultFav = (data) => {
    try {
      let array = []
      data.forEach(element => {
        if (element.favourite === true) {
          array.push(element._id)
        }
      });
      console.log(array)
      setAddFav(array)
    } catch (error) {

    }
  }

  // *For Checkbox
  const handleFilter = (value) => {
    Object.keys(value).forEach(element => {
      searchParams.set(element, value[element])
    });
    setSearchParams(searchParams)

    setSearch(searchParams.get('search'));
    setCat({ categoryId: searchParams.get('categoryId'), categoryName: searchParams.get('categoryName') });
    setCity({ cityId: searchParams.get('cityId'), cityName: searchParams.get('cityName') });
    setArea({ areaId: searchParams.get('areaId'), areaName: searchParams.get('areaName') });
  };

  // *For Collapse
  const handleCollapse = (value) => {
    const currentIndex = expand.indexOf(value);
    const newExpand = [...expand];

    if (currentIndex === -1) {
      newExpand.push(value);
    } else {
      newExpand.splice(currentIndex, 1);
    }

    setExpand(newExpand);
  };

  // *For Delete Filter
  const deleteFilter = (key, id) => {
    try {
      let array = [...selectedFiltered]
      const index = array.findIndex(e => e.id === id)
      if (index !== -1) {
        key.forEach(element => {
          searchParams.delete(element)
          setSearchParams(searchParams)
        });
        array.splice(index, 1)
      }
      setSelectedFiltered(array)
    } catch (error) {
      console.log('file: SearchAds.js => line 164 => deleteFilter => error', error)
    }
  }

  // *For Select Filter
  const selectFilter = () => {
    try {
      let array = []
      const category = { id: searchParams.get('categoryId'), name: searchParams.get('categoryName'), key: ['categoryId', 'categoryName'] }
      const city = { id: searchParams.get('cityId'), name: searchParams.get('cityName'), key: ['cityId', 'cityName'] }
      const area = { id: searchParams.get('areaId'), name: searchParams.get('areaName'), key: ['areaId', 'cityName'] }
      if (category.name) array.push(category)
      if (city.name) array.push(city)
      if (area.name) array.push(area)
      setSelectedFiltered(array)
    } catch (error) {
      console.log('file: SearchAds.js => line 178 => selectFilter => error', error)
    }
  }

  // *For Clear Filter
  const clearFilter = () => {
    navigate('/item')
    setSearch('');
    setCat({ categoryId: '', categoryName: '' });
    setCity({ cityId: '', cityName: '' });
    setArea({ areaId: '', areaName: '' });
    setMinPrice('');
    setMaxPrice('');
    setSelectedFiltered([])
    setInputMinValue(1);
    setInputMaxValue(100000);
  }

  // *For Set Price
  const handleMinPrice = (e) => {
    const value = e.target.value ? e.target.value : inputMinValue
    setInputMinValue(value)
    setTimeout(() => {
      searchParams.set('minPrice', value)
      searchParams.set('maxPrice', inputMaxValue)
      setSearchParams(searchParams)
      setMinPrice(searchParams.get('minPrice'));
      setMaxPrice(searchParams.get('maxPrice'));
    }, 1000);
  }

  const handleMaxPrice = (e) => {
    const value = e.target.value ? e.target.value : inputMaxValue
    setInputMaxValue(value)
    setTimeout(() => {
      searchParams.set('maxPrice', value)
      searchParams.set('minPrice', inputMinValue)
      setSearchParams(searchParams)
      setMinPrice(searchParams.get('minPrice'));
      setMaxPrice(searchParams.get('maxPrice'));
    }, 1000);
  }
  const getAutoCompleteDropDown = async () => {
    try {
      const search = ''
      const data = await Service.autoCompleteDropDown(search)
      setAutoCompleteDropDown(data)
    } catch (error) {
      console.log('file: Home.js => line 53 => getAutoCompleteDropDown => error', error)
    }
  }

  // function handleChange(event, value) {

  //   let searchValue
  //   if (value) {
  //     searchValue = value
  //   } else {
  //     searchValue = event.target.value
  //   }
  //   setTimeout(() => {
  //     setSearch(searchValue)
  //     navigate(`/item?search=${searchValue}`)
  //   }, 1000);
  // }

  const [state, setState] = React.useState(false);

  const toggleDrawer = () => {


    setState(!state);
  };

  useEffect(() => {
    getSideFilter()
  }, [])

  useEffect(() => {
    getAdsByFilter()
    selectFilter()
  }, [search, city, maxPrice, minPrice, cat, area, user])

  return (
    <Layout>

      <SignIn openDialog={openSignInDialog} onClose={() => setOpenSignInDialog(false)} />

      {/* <Breadcrumbs aria-label="breadcrumb" sx={{ fontSize: "25px", my: 2 }} >
        <Typography component={Link} to="/" sx={{ color: "#2c457a", fontSize: "18px" }}> Home</Typography>
        <Typography component={Link} to="/sell" sx={{ color: "#2c457a", fontSize: "18px" }}> Sell</Typography>

        <Typography color="#2c457a" sx={{ fontSize: "18px", mt: 0.3 }} >Test</Typography>
      </Breadcrumbs> */}

      <Typography sx={{ fontWeight: 700, fontSize: '2rem', mb: 2 }}>{cat.categoryName ? cat.categoryName : search}</Typography>
      <Typography onClick={() => toggleDrawer()} variant="body1" sx={{ display: { md: "none", xs: "block" }, pb: 2, fontWeight: '600', fontSize: '1.3rem', }}>
        <FilterAlt sx={{ fontSize: 34, verticalAlign: 'bottom', color: "#3b5998" }} /> Filters:
        <Typography sx={{ mt: -5, textAlign: "right" }}><ToggleButton value="right" aria-label="right aligned" >
          <FormatAlignLeftIcon sx={{ textAlign: "right" }} />
        </ToggleButton></Typography>
      </Typography>

      <SwipeableDrawer
        anchor={"left"}
        open={state}
        onClose={() => toggleDrawer()}
        onOpen={() => toggleDrawer()}
        onClick={() => toggleDrawer(false)}
        sx={{ display: { md: "none" }, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: "300px" }, }}
      >
        <Box>
          <Typography variant="body1" sx={{ pb: 2, fontWeight: '600', fontSize: '1.3rem', p: 3 }}>
            <FilterAlt sx={{ fontSize: 34, verticalAlign: 'bottom', color: "#3b5998" }} /> Filters:
          </Typography>

          <Divider />
          <Typography sx={{ fontWeight: 700, fontSize: '1.2rem', p: 3 }}>Category</Typography>

          <Box sx={{ color: 'rgba(0,47,52,.64)', pl: 3 }}>
            {category.map((category, index) => (
              <Fragment>
                <ListItemButton key={index} disableRipple sx={{ color: '#000', ':hover': { bgcolor: 'transparent' } }}>
                  <ListItemText primary={<Typography onClick={() => { handleFilter({ categoryId: category._id, categoryName: category.name }) }} sx={{ textTransform: 'capitalize', fontWeight: category._id === cat.categoryId && 900 }}>{category.name}</Typography>} />
                  {expand.indexOf(category._id) !== -1 ?
                    <ExpandLess onClick={() => handleCollapse(category._id)} />
                    :
                    <ExpandMore onClick={() => handleCollapse(category._id)} />
                  }
                </ListItemButton>
                {category.Sub_Category.length > 0 &&
                  category.Sub_Category.map((subCat, i) => (
                    <Collapse key={i} in={expand.indexOf(category._id) !== -1 ? true : false} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding >
                        <ListItemButton disableRipple onClick={() => { handleFilter({ categoryId: subCat._id }) }} sx={{ color: '#000', p: '8px 16px 8px 32px', ':hover': { bgcolor: 'transparent' } }}>
                          <ListItemText primary={<Typography sx={{ textTransform: 'capitalize', fontWeight: subCat._id === cat.categoryId && 900 }}>{subCat.name}</Typography>} />
                        </ListItemButton>
                      </List>
                    </Collapse>
                  ))
                }
              </Fragment>
            ))}
          </Box>

          <Typography sx={{ fontWeight: 700, fontSize: '1.2rem', pl: 3 }}>Location</Typography>
          <Box sx={{ color: 'rgba(0,47,52,.64)', pl: 3 }}>

            {location.map((location, index) => (
              <Fragment>
                <ListItemButton key={index} disableRipple sx={{ color: '#000', ':hover': { bgcolor: 'transparent' } }}>
                  <ListItemText primary={<Typography onClick={() => { handleFilter({ cityId: location._id, cityName: location.name, areaId: '', areaName: '' }) }} sx={{ textTransform: 'capitalize', fontWeight: location._id === city.cityId && 900 }}>{location.name}</Typography>} />
                  {expand.indexOf(location._id) !== -1 ?
                    <ExpandLess onClick={() => handleCollapse(location._id)} />
                    :
                    <ExpandMore onClick={() => handleCollapse(location._id)} />
                  }
                </ListItemButton>
                {location.Areas.length > 0 &&
                  location.Areas.map((street, i) => (
                    <Collapse key={i} in={expand.indexOf(location._id) !== -1 ? true : false} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItemButton disableRipple onClick={() => { handleFilter({ areaId: street._id, areaName: street.Subdivison_name }) }} sx={{ color: '#000', p: '8px 16px 8px 32px', ':hover': { bgcolor: 'transparent' } }}>
                          <ListItemText primary={<Typography sx={{ textTransform: 'capitalize', fontWeight: street._id === area.areaId && 900 }}>{street.Subdivison_name}</Typography>} />
                        </ListItemButton>
                      </List>
                    </Collapse>
                  ))
                }
              </Fragment>
            ))}
          </Box>
          <Divider />
          <Typography sx={{ fontWeight: 700, fontSize: '1.2rem', pl: 3, p: 2 }}>Price</Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between", pl: 3, p: 1 }}>
            <FormControl fullWidth variant="standard" sx={{ pl: 3 }}>
              <Input
                placeholder='min price'
                value={inputMinValue}
                startAdornment={<InputAdornment position="start">Rs</InputAdornment>}
                onChange={handleMinPrice}
              />
            </FormControl>
            <FormControl fullWidth variant="standard" sx={{ pl: 3 }}>
              <Input
                placeholder='max price'
                value={inputMaxValue}
                startAdornment={<InputAdornment position="start">Rs</InputAdornment>}
                onChange={handleMaxPrice}
              />
            </FormControl>
          </Box>
        </Box>
      </SwipeableDrawer>
      <Grid container>
        <Grid item xs={4} md={3} sx={{ mr: { md: 3, xs: 2 }, display: { md: "block", xs: "none" } }}>
          <Typography variant="body1" sx={{ display: { xs: "none", md: "block" }, pb: 2, fontWeight: '600', fontSize: '1.3rem', }}>
            <FilterAlt sx={{ fontSize: 34, verticalAlign: 'bottom', color: "#3b5998" }} /> Filters:
          </Typography>
          <Divider />
          <Typography sx={{ fontWeight: 700, fontSize: '1.2rem', p: 1 }}>Category</Typography>

          <Box sx={{ color: 'rgba(0,47,52,.64)' }}>
            {category.map((category, index) => (
              <Fragment>
                <ListItemButton key={index} disableRipple sx={{ color: '#000', ':hover': { bgcolor: 'transparent' } }}>
                  <ListItemText primary={<Typography onClick={() => { handleFilter({ categoryId: category._id, categoryName: category.name }) }} sx={{ textTransform: 'capitalize', fontWeight: category._id === cat.categoryId && 900 }}>{category.name}</Typography>} />
                  {expand.indexOf(category._id) !== -1 ?
                    <ExpandLess onClick={() => handleCollapse(category._id)} />
                    :
                    <ExpandMore onClick={() => handleCollapse(category._id)} />
                  }
                </ListItemButton>
                {category.Sub_Category.length > 0 &&
                  category.Sub_Category.map((subCat, i) => (
                    <Collapse key={i} in={expand.indexOf(category._id) !== -1 ? true : false} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding >
                        <ListItemButton disableRipple onClick={() => { handleFilter({ categoryId: subCat._id }) }} sx={{ color: '#000', p: '8px 16px 8px 32px', ':hover': { bgcolor: 'transparent' } }}>
                          <ListItemText primary={<Typography sx={{ textTransform: 'capitalize', fontWeight: subCat._id === cat.categoryId && 900 }}>{subCat.name}</Typography>} />
                        </ListItemButton>
                      </List>
                    </Collapse>
                  ))
                }
              </Fragment>
            ))}
          </Box>

          <Typography sx={{ fontWeight: 700, fontSize: '1.2rem', p: 1 }}>Location</Typography>
          <Box sx={{ color: 'rgba(0,47,52,.64)' }}>

            {location.map((location, index) => (
              <Fragment>
                <ListItemButton key={index} disableRipple sx={{ color: '#000', ':hover': { bgcolor: 'transparent' } }}>
                  <ListItemText primary={<Typography onClick={() => { handleFilter({ cityId: location._id, cityName: location.name, areaId: '', areaName: '' }) }} sx={{ textTransform: 'capitalize', fontWeight: location._id === city.cityId && 900 }}>{location.name}</Typography>} />
                  {expand.indexOf(location._id) !== -1 ?
                    <ExpandLess onClick={() => handleCollapse(location._id)} />
                    :
                    <ExpandMore onClick={() => handleCollapse(location._id)} />
                  }
                </ListItemButton>
                {location.Areas.length > 0 &&
                  location.Areas.map((street, i) => (
                    <Collapse key={i} in={expand.indexOf(location._id) !== -1 ? true : false} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItemButton disableRipple onClick={() => { handleFilter({ areaId: street._id, areaName: street.Subdivison_name }) }} sx={{ color: '#000', p: '8px 16px 8px 32px', ':hover': { bgcolor: 'transparent' } }}>
                          <ListItemText primary={<Typography sx={{ textTransform: 'capitalize', fontWeight: street._id === area.areaId && 900 }}>{street.Subdivison_name}</Typography>} />
                        </ListItemButton>
                      </List>
                    </Collapse>
                  ))
                }
              </Fragment>
            ))}
          </Box>
          <Divider />
          <Typography sx={{ fontWeight: 700, fontSize: '1.2rem', p: 1 }}>Price</Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between", p: 1 }}>
            <FormControl fullWidth variant="standard" sx={{ mr: 1 }}>
              <Input
                placeholder='min price'
                value={inputMinValue}
                startAdornment={<InputAdornment position="start">Rs</InputAdornment>}
                onChange={handleMinPrice}
              />
            </FormControl>
            <FormControl fullWidth variant="standard" sx={{ ml: 1 }}>
              <Input
                placeholder='max price'
                value={inputMaxValue}
                startAdornment={<InputAdornment position="start">Rs</InputAdornment>}
                onChange={handleMaxPrice}
              />
            </FormControl>
          </Box>
          {/* <Typography sx={{ fontWeight: 700, fontSize: '1rem', p: 1 }}>LOCATIONS</Typography>
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={cities}
              getOptionLabel={(option) => option.name}
              sx={{ mr: 1 }}

              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}
                >
                  {option.name}
                </Box>
              )}
              renderInput={(params) => (
                <>
                  <TextField
                    {...params}
                    placeholder='Location'
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <AddLocationAltOutlinedIcon />
                        </InputAdornment>
                      )

                    }}
                  />
                </>
              )}
            />
          </FormControl> */}
          {/* <Typography sx={{ fontWeight: 700, fontSize: '1.2rem', p: 1 }}>Price</Typography>
          <Slider
            getAriaLabel={() => 'Price range'}
            value={value}
            onChange={handleChange}
            valueLabelDisplay="auto"

          /> */}
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container alignItems={"center"}>
            {/* ========== Selected Filter items ========== */}
            <Grid item xs={8} >
              {selectedFiltered.length > 0 &&
                <Fragment>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontSize: { md: "17px", xs: "16px" } }}>Filtered by:</Typography>
                    {selectedFiltered.map((filter, index) => (
                      <Chip key={index} label={filter.name} sx={{ m: 0.5, fontSize: { md: "11px", xs: "11px" }, height: 26 }} onDelete={() => deleteFilter(filter.key, filter.id)} />
                    ))}
                    <Typography variant="body2" sx={{ display: { md: "flex", xs: "none" }, fontSize: { md: "17px", xs: "16px" }, cursor: "pointer", my: 1 }} color="primary" onClick={() => clearFilter()}>Clear All</Typography>
                  </Box>
                </Fragment>
              }
            </Grid>

            {/* ========== Text Search Field ========== */}

            <Grid item xs={12} md={4} sx={{ display: "flex", }}>
              <FormControl sx={{ width: '100%', backgroundColor: "#FFFFFF", borderRadius: "2%", my: 0.6, display: "flex" }}>

                <Autocomplete
                  size='small'
                  freeSolo
                  disableClearable
                  options={autoCompleteDropDown.map((option) => option.title)}
                  onChange={handleChange}
                  defaultValue={cat.categoryName ? cat.categoryName : search}
                  style={{ maxHeight: "20%" }}
                  onClick={() => clearFilter()}
                  renderInput={(params) => (
                    <TextField

                      {...params}
                      onChange={handleChange}
                      placeholder="search"
                      style={{ color: "#BCBCBC", }}
                      InputProps={{
                        ...params.InputProps,
                        type: 'search',

                      }}
                    />
                  )}
                />

              </FormControl>

              {/* <TextField
                size='small'
                fullWidth
                sx={{ my: 0.5 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search color="primary" />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                placeholder="Search"
              /> */}
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ mb: 2, }} />
            </Grid>
          </Grid>
          {!loader ?
            ads.length > 0 ?
              <Fragment>
                {ads.map((item, index) => (
                  <Card key={index} sx={{ display: 'flex', height: '150px', m: 2 }}>
                    <Link to={`/ads/${item._id}`}>
                      <CardMedia
                        component="img"
                        sx={{ width: 230, objectFit: "unset", height: "100%" }}
                        image={item.images[0]}
                        alt="Live from space album cover"
                      />
                    </Link>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

                      <CardContent sx={{ flex: '1 0 auto' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Link to={`/ads/${item._id}`}>
                            <Typography component="div" sx={{ wordBreak: 'break-all', fontSize: '1.5rem', color: '#000' }}>
                              {item.title}
                            </Typography>
                          </Link>
                          {addFav.findIndex(e => e === item._id) !== -1 ?
                            <Favorite sx={{ color: '#2c457a', cursor: 'pointer' }} onClick={() => addToFav(item._id)} />
                            : <FavoriteBorderOutlined sx={{ cursor: 'pointer', ':hover': { color: '#2c457a' } }} onClick={() => addToFav(item._id)} />
                          }
                        </Box>

                        <Typography variant="h5" component="div" sx={{ flex: '1 0 auto', fontWeight: 600, pt: 1, fontSize: { md: "11px", xs: "9px" } }}>
                          RS {item.price}
                        </Typography>
                        <Typography component="p" sx={{ fontSize: '12px', pt: 2 }}>
                          {item.location}  {moment(item.posted_On).format('DD MMM YYYY')}
                        </Typography>
                      </CardContent>

                    </Box>

                  </Card>
                ))}
                {totalAds !== ads?.length &&
                  <Box sx={{ textAlign: "center", my: 2 }} >
                    <LoadingButton loading={loading} variant='contained' onClick={() => getAdsByFilter(1)}>
                      Load More
                    </LoadingButton>
                  </Box>
                }
              </Fragment>
              :
              <Box sx={{ textAlign: 'center' }}>
                <Typography sx={{ fontSize: '2rem' }}>Oops... we didn't find anything that matches</Typography>
                <Typography>Try to search for something more general, change the filters or check for spelling mistakes</Typography>
              </Box>
            :
            <Box sx={{ textAlign: 'center' }} >
              <CircularProgress />
            </Box>
          }
        </Grid>
      </Grid>

    </Layout >
  )
}

export default SearchAds