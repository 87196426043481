import { Avatar, Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Images from '../../assets/Images/Images';
import Layout from './../../layout/Layout';
import { Service } from './../../config/service';
import { Link } from 'react-router-dom';

function Sell() {
    const [category, setCategory] = useState([])
    async function fetchData() {
        const { Data } = await Service.getCategory()
        setCategory(Data)
    }
    useEffect(() => {
        fetchData()
    }, [])
    return (
        <Layout>
            <Box sx={{ textAlign: 'center' }}>
                <Typography sx={{ fontSize: '25px', fontWeight: 'Bold', m: 4 }}>
                    What are you Listing?
                </Typography>
                <img alt='logo' src={Images.selllogo} />
            </Box>
            <Box sx={{ my: 2 }}>
                <Grid container spacing={2} >



                    {category.map((item) => {
                        return (
                            <Grid item xs={4} md={3} key={item._id} >
                                {/* <Box sx={{ my: 2 }} > */}
                                <Link to={`${item.name}`} state={item}>
                                    <Box
                                        style={{
                                            backgroundColor: '#F9F9F9', width: '59px', height: '59px',
                                            margin: 'auto',
                                            borderRadius: '90%'
                                        }} >
                                        <img alt={item.name} src={item.image} style={{
                                            width: '25px', height: '22px', position: 'relative',

                                            top: 17, left: 22
                                        }} />
                                    </Box>
                                    <Typography sx={{ py: 1, fontSize: '14px', color: '#484848', fontWeight: 'bold', textAlign: 'center' }}>{item.name}</Typography>
                                </Link>

                                {/* </Box> */}


                            </Grid>
                        )
                    })}







                </Grid>
            </Box>

        </Layout>
    )
}

export default Sell


