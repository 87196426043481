import { Box, CardMedia, Container, Grid, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import Images from '../assets/Images/Images';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Link } from 'react-router-dom';
import { Service } from '../config/service';


function Footer() {

  const [category, setCategory] = useState([]);

  const getCategory = async () => {
    try {
      const { Data } = await Service.getCategory()
      setCategory(Data)
    } catch (error) {
      console.log('file: Footer.js => line 15 => getCategory => error', error)
    }
  }

  useEffect(() => {
    getCategory()
  }, []);

  return (

    <Box sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: "#3B5998", borderColor: "#707070", paddingY: 5, pb: 10, mt: 4 }}>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={6} lg={3} md={6} >
            <Typography sx={{ fontSize: "20px", color: "#FFFFFF", padding: 2 }}>
              <strong>Popular Categories</strong>
            </Typography>
            {category.map((cat, index) => (
              <Typography key={index} component={Link} to={`/item?category=${cat.name}&id=${cat._id}`} sx={{ fontSize: "20px", color: "#FFFFFF", padding: 2 }}>
                {cat.name}
                <br />
              </Typography>
            ))}
          </Grid>
          <Grid item xs={6} lg={3} md={6}>
            <Typography sx={{ fontSize: "20px", color: "#FFFFFF", padding: 2 }}>
              <strong>Quick Links</strong>
            </Typography>
            <Typography component={Link} to="/about-us" sx={{ fontSize: "20px", color: "#FFFFFF", padding: 2 }}>
              About Us <br />
            </Typography>
            <Typography component={Link} to="/" sx={{ fontSize: "20px", color: "#FFFFFF", padding: 2 }}>
              Advertising <br />
            </Typography>
            <Typography component={Link} to="/" sx={{ fontSize: "20px", color: "#FFFFFF", padding: 2 }}>
              Careers<br />
            </Typography>
            <Typography component={Link} to="/terms-of-use" sx={{ fontSize: "20px", color: "#FFFFFF", padding: 2 }}>
              Terms of Use<br />
            </Typography>

          </Grid>
          <Grid item xs={6} lg={3} md={6}>
            <Typography sx={{ fontSize: "20px", color: "#FFFFFF", padding: 2 }}>
              <strong>Support</strong>
            </Typography>
            <Typography component={Link} to="/" sx={{ fontSize: "20px", color: "#FFFFFF", padding: 2 }}>
              Help<br />
            </Typography>
            <Typography component={Link} to="/contact-us" sx={{ fontSize: "20px", color: "#FFFFFF", padding: 2 }}>
              Contact Us <br />
            </Typography>
            <Typography component={Link} to="/privacy-policy" sx={{ fontSize: "20px", color: "#FFFFFF", padding: 2 }}>
              Privacy Policy<br />
            </Typography>

          </Grid>

          <Grid item xs={6} lg={3} md={6}>
            <Typography sx={{ fontSize: "20px", color: "#FFFFFF", padding: 2 }}>
              <strong>Follow Us</strong>
            </Typography>
            <Typography sx={{ fontSize: "20px", color: "#FFFFFF", padding: 1 }}>
              <FacebookIcon /> <InstagramIcon /> <TwitterIcon /> <YouTubeIcon />
            </Typography>
            <Typography sx={{ fontSize: "18px", color: "#FFFFFF", padding: 1 }}>
              GET YOUR APP TODAY
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', paddingTop: 2, paddingRight: 4 }}>
              <CardMedia
                component="img"
                height="20%"
                sx={{ width: "110px !important" }}
                image={Images.pic16}
                alt="load"
              />
              <CardMedia
                component="img"
                height="20%"
                sx={{ width: "110px !important" }}
                image={Images.pic17}
                alt="load"
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
export default Footer